import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: () => {
      const lang = window.localStorage.getItem("lang") || "en";
      return `/${lang}`;
    },
  },
  {
    path: "/:lang?",
    component: () => import("../App.vue"),
    children: [
      // Landing Pages
      {
        path: "ned-college",
        component: () => import("../views/landings/landing_1122.vue"),
        name: "Landing1122",
        meta: {
          landing: true,
          title: "Ned College Promo",
          langs: ["en"],
        },
      },
      {
        path: "visaespagne/marocains",
        component: () => import("../views/landings/landing_0123.vue"),
        name: "Landing0123",
        meta: {
          landing: true,
          title: "Visa Espagne",
          langs: ["fr"],
        },
      },
      {
        path: "studentvisa/russianspeaker",
        component: () => import("../views/landings/landing_0223.vue"),
        name: "Landing0223",
        meta: {
          landing: true,
          title: "Rusia",
          langs: ["ru"],
        },
      },
      // Main Layout
      {
        path: "",
        component: () => import("../views/MainLayout.vue"),
        children: [
          {
            path: "",
            name: "home",
            component: () => import("../views/Home.vue"),
            meta: {
              navbarColor: "primary",
              title: "Home",
              langs: ["en", "es"],
            },
          },
          {
            path: "campus/barcelona",
            name: "barcelona",
            component: () => import("../views/Barcelona.vue"),
            meta: {
              navbarColor: "secondary",
              title: "Barcelona",
              langs: ["en", "es"],
            },
          },
          {
            path: "campus/madrid",
            name: "madrid",
            component: () => import("../views/Madrid.vue"),
            meta: {
              navbarColor: "tertiary",
              title: "Madrid",
              langs: ["en", "es"],
            },
          },
          {
            path: "courses/spanish",
            name: "spanishcourses",
            component: () => import("../views/SpanishCourses.vue"),
            meta: {
              navbarColor: "primary",
              title: "Spanish Courses",
              langs: ["en", "es"],
            },
          },
          {
            path: "courses/english",
            name: "englishcourses",
            component: () => import("../views/EnglishCourses.vue"),
            meta: {
              navbarColor: "primary",
              title: "English Courses",
              langs: ["en", "es"],
            },
          },
          {
            path: "student-life/accommodation",
            name: "accommodation",
            component: () => import("../views/Accommodation.vue"),
            meta: {
              navbarColor: "primary",
              title: "Accommodation",
              langs: ["en", "es"],
            },
          },
          {
            path: "student-life/visa",
            name: "visa",
            component: () => import("../views/Visa.vue"),
            meta: {
              navbarColor: "primary",
              title: "Visa",
              langs: ["en", "es"],
            },
          },
          {
            path: "student-life/health-insurance",
            name: "healthinsurance",
            component: () => import("../views/HealthInsurance.vue"),
            meta: {
              navbarColor: "primary",
              title: "Health Insurance",
              langs: ["en", "es"],
            },
          },
          {
            path: "student-life/exams",
            name: "exams",
            component: () => import("../views/Exams.vue"),
            meta: {
              navbarColor: "primary",
              title: "Exams",
              langs: ["en", "es"],
            },
          },
          {
            path: "student-life/extra-activities",
            name: "extraactivities",
            component: () => import("../views/ExtraActivities.vue"),
            meta: {
              navbarColor: "primary",
              title: "Extra Activities",
              langs: ["en", "es"],
            },
          },
          {
            path: "placement-test",
            name: "placement_test",
            component: () => import("../views/PlacementTest.vue"),
            meta: {
              navbarColor: "primary",
              title: "Placement Test",
              langs: ["en", "es"],
            },
          },
          {
            path: "price-list",
            name: "price_list",
            component: () => import("../views/PriceList.vue"),
            meta: {
              navbarColor: "primary",
              title: "Price List",
              langs: ["en", "es"],
            },
          },
          {
            path: "contact-us",
            name: "contactus",
            component: () => import("../views/ContactUs.vue"),
            meta: {
              navbarColor: "primary",
              footer_no_form: true,
              title: "Contact Us",
              langs: ["en", "es"],
            },
          },
          {
            path: "information-sent",
            name: "thankyou",
            component: () => import("../views/ThankYou.vue"),
            meta: {
              navbarColor: "primary",
              footer_no_form: true,
              title: "Thank you",
              langs: ["en", "es"],
            },
          },
          {
            path: "privacy-policy",
            name: "privacypolicy",
            component: () => import("../views/PrivacyPolicy.vue"),
            meta: {
              navbarColor: "primary",
              title: "Privacy Policy",
              langs: ["en", "es"],
            },
          },
          {
            path: "whatsapp",
            name: "whatsapp",
            component: () => import("../views/Whatsapp.vue"),
            meta: {
              navbarColor: "primary",
              title: "Whatsapp",
              langs: ["en", "es"],
            },
          },
          {
            path: "ireland-8months-info",
            name: "landingIreland",
            component: () => import("../views/landings/landing_0816.vue"),
            meta: {
              landing: true,
              title: "Ireland 8 months",
              langs: ["en", "es"],
            },
          },
          {
            path: "ireland-8months-info-sent",
            name: "landingIrelandThanks",
            component: () => import("../views/ThankYou.vue"),
            meta: {
              navbarColor: "primary",
              footer_no_form: true,
              title: "Thank you",
              langs: ["en", "es"],
            },
          },
          {
            path: "404",
            name: "error404",
            component: () => import("../views/Error404.vue"),
            meta: {
              title: "404",
              langs: ["en", "es"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/learnspanish",
    component: () => import("../views/landings/landing_0323.vue"),
    name: "learnspanish",
    meta: {
      landing: true,
      title: "Learn Spanish",
      langs: ["en"],
    },
  },
  {
    path: "/learnenglish",
    component: () => import("../views/landings/landing_0323.vue"),
    name: "learnenglish",
    meta: {
      landing: true,
      title: "Learn English",
      langs: ["es"],
    },
  },
  {
    path: "/thanks",
    component: () => import("../views/landings/thankyou.vue"),
    name: "thanks",
    meta: {
      landing: true,
      title: "Thank you",
      langs: ["es", "en"],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  window.scrollTo(0, 0);

  // Set title for each page
  document.title = `NLCollege - ${to.meta.title}`;

  let lang = to.params.lang || window.localStorage.getItem("lang");

  if (from.path == "/" && window.localStorage.getItem("lang")) {
    lang = window.localStorage.getItem("lang");
  }

  if (!to.meta.langs.includes(lang)) lang = to.meta.langs[0];

  window.localStorage.setItem("lang", lang);

  if (to.params.lang !== lang) {
    return {
      name: to.name,
      params: {
        lang: lang,
      },
      query: to.query,
    };
  }

  return true;
});

export default router;
