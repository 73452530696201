export default {
  menus: {
    campus: "Campus",
    courses: "Cursos",
    contact_us: "Contacto",
    student_life: "Vida de Estudiante",
    accommodation: "Alojamiento",
    exams: "Exámenes",
    visa: "Visado",
    extra_activities: "Actividades Extra",
    health_insurance: "Seguro Médico",
    spanish_courses: "Cursos de Español",
    english_courses: "Cursos de Inglés",
    placement_test: "Prueba de Nivel",
    price_list: "Lista de Precios",
  },
  home: {
    header: {
      title: "Vive, aprende y habla!",
      subtitle: `Aprende inglés o español con nuestros profesores nativos y vive la mejor experiencia en 
          <b class="text-secondary">Barcelona</b> y
          <b class="text-tertiary">Madrid</b>`,
    },
    welcome_spain: {
      title: "¡BIENVENIDO A ESPAÑA!",
      subtitle:
        "¡Es hora de aprender una nueva lengua y de tener otra perspectiva de vida!",
      features: {
        1: {
          title: "Riqueza cultural",
          desc: "Desde grandes artistas como Picasso, Gaudí y Salvador Dalí hasta los festivales tradicionales y mucha riqueza histórica: ¡En España respirarás cultura!",
        },
        2: {
          title: "Gente española",
          desc: "¡Los españoles son acogedores y simpáticos! Verás que la gente es receptiva y apasionada por su país. Acostumbrados a vivir con extranjeros, la gente española te recibirá con los brazos abiertos.",
        },
        3: {
          title: "Gastronomía",
          desc: "Si eres un apasionado de la comida, ¡Estás en el sitio adecuado! Desde las famosas tapas a las paellas tradicionales, ¡prepárate para saborear comidas que nunca olvidarás!",
        },
        4: {
          title: "Playas preciosas",
          desc: "Con una media de 300 días soleados al año, el clima en España es perfecto para relajarse en la playa. De Ibiza a Mallorca, no importa tu estilo, ¡Seguro que acabas enamorado de las playas de España!",
        },
      },
      button: "Descubre nuestros cursos",
    },
    welcome_nlc: {
      title: "Bienvenidos a NL College",
      subtitle:
        "Nuestra escuela abrió sus puertas en 2017 y nuestro objetivo principal es que aprendas español, pero también que crees buenos recuerdos y experiencias inolvidables. ¡Prepárate para un desarrollo personal y profesional además de crear amistades para toda la vida!",
      features: {
        1: "Estructura única",
        2: "Flexibilidad horaria",
        3: "Actividades extra",
        4: "Clases extracurriculares",
      },
      button: "QUIERO SER ESTUDIANTE EN NL COLLEGE",
    },
    certifications: "Acreditaciones",
    campuses: {
      barcelona: {
        title: "Barcelona",
        desc: `<p>
            La mejor manera de aprender español es vivir en España y lo puedes hacer viviendo en uno de los destinos más famosos del mundo, Barcelona. Es como vivir en una ciudad de ensueño llena de cultura, buena comida e ambiente increíble. Con un estilo que mezcla la gran ciudad con playas maravillosas para amantes de la música, arte y deportes, los estudiantes tendrán una experiencia inolvidable.
          </p>
          <p>
            <b>
              ¡Prepárate para vivir en una ciudad vibrante, alegre y llena de vida!
            </b>
          </p>`,
        button: "QUIERO ESTUDIAR EN BARCELONA",
      },
      madrid: {
        title: "Madrid",
        desc: `<p>
            Vivir en Madrid es simplemente vivir en una de las ciudades más encantadoras y más conocidas del mundo. Con una de las mejores calidades de vida urbana en Europa, prepárate para estudiar español mientras experimentas lo mejor de una gran ciudad, siempre con un alto nivel en enseñanza.
          </p>
          <p>
            <b>¡Prepárate para vivir en el corazón de España!</b>
          </p>`,
        button: "QUIERO ESTUDIAR EN MADRID",
      },
    },
    courses: {
      title: "Sobre nuestros cursos",
      subtitle:
        "En NL College ofrecemos el curso de español que mejor encaja con tu estilo de vida. Escoge el curso con el que más te identifiques y ¡prepárate para empezar tu inmersión en la cultura española!",
      features: {
        1: {
          title: "Intensivo",
          subtitle:
            "El curso más conocido y popular en nuestra escuela, para aprender a un ritmo rápido. Los cursos están diseñados para adultos que quieren mejorar su comunicación, su escritura y su gramática.",
          button: "QUIERO SABER MÁS",
        },
        2: {
          title: "Semi-Intensivo",
          subtitle:
            "Este curso está dirigido a aquellos que tienen menos tiempo para invertir en estudiar español o aquellos que quieren combinarlo con su trabajo u otros estudios.",
          button: "QUIERO SABER MÁS",
        },
        3: {
          title: "Clases por la tarde",
          subtitle:
            "Nuestras clases de español por la tarde son perfectas para personas con un trabajo a tiempo completo, estudios universitarios u otras responsabilidades durante el día.",
          button: "QUIERO SABER MÁS",
        },
      },
    },
    support: {
      title: "Ayuda en tu lengua materna",
      subtitle:
        "Sabemos que irse a vivir a otro país y aprender otra lengua puede ser un reto y queremos estar en tu proceso de escoger un curso de español para ser lo más transparentes posible. Por eso, nuestro equipo tiene profesionales cualificados preparados para ayudarte y contestar a todas tus preguntas en tu lengua materna.",
      button: "HABLA CON NUESTROS AGENTES",
    },
    student_life: {
      title: "Vida de estudiante",
      subtitle:
        "Estudiar en NL College significa aprender español de manera excelente y seguir viviendo la experiencia española al máximo. Desde Barcelona y Madrid, ¡tómate tu tiempo para hacer nuevos amigos y crear recuerdos para toda la vida!",
      features: {
        1: {
          title: "Alojamiento",
          button: "QUIERO SABER MÁS",
        },
        2: {
          title: "Visado",
          button: "QUIERO SABER MÁS",
        },
        3: {
          title: "Seguro Médico",
          button: "QUIERO SABER MÁS",
        },
        4: {
          title: "Exámenes",
          button: "QUIERO SABER MÁS",
        },
      },
    },
  },
  barcelona: {
    header: {
      title: "Barcelona",
    },
    campus: {
      title: "Nuestro Campus",
      desc: `<p class="text-medium">
          Con un edificio moderno y un ambiente dinámico e inmersivo en la cultura española, aprovecha todo lo que NL College te ofrece para desarrollar tu español con excelencia.
        </p>

        <p class="text-medium">
          <b>
            Además de la calidad de la enseñanza, también nos preocupa tu experiencia dentro y fuera del aula.
          </b>
          Nuestras clases respetan el límite máximo de 12 estudiantes por grupo; ofrecemos cursos de español que cubren tus necesidades con profesores especializados y nativos.
        </p>
        <p class="text-medium">
          Con un edificio moderno y una atmósfera dinámica e inmersiva en
          cultura Española, aprovecha todo lo que tiene NL College
          para ofrecerte a desarrollar tu español con excelencia.
        </p>`,
      button: "QUIERO ESTUDIAR CON VOSOTROS",
      features: {
        1: "5 pisos con clases",
        2: "Instalaciones nuevas y modernas",
        3: "Sala de estudiantes",
      },
    },
    why_barcelona: {
      title: "¿Por qué escoger Barcelona?",
      desc: `
        <p>
          Barcelona es la segunda ciudad más grande de España, la tercera más visitada de Europa y tiene mucho que ofrecer.
        </p>
        <p>
          Aprender español en Barcelona te da la oportunidad de descubrir una ciudad llena de colores con una vida nocturna increíble. Además, ¡vivirás rodeado de cultura, deporte, buena comida, historia y arte! De Gaudí al Barrio Gótico, estarás encantado con cada esquina de Barcelona.
        </p>
        <p>
          Animamos a nuestros estudiantes a practicar español cada día, ¡todo el rato! Tanto si participas en nuestras actividades extras o en tu día a día. ¡Vive Barcelona al máximo! 
        </p>
        <p class="text-dark"><b>¡Empápate de la ciudad!</b></p>
        `,
      button: "QUIERO ESTUDIAR EN BARCELONA",
    },
    team: {
      title: "Equipo",
      subtitle:
        "Nuestro equipo está preparado para ayudarte antes y después de llegar a Barcelona, contestando todas tus preguntas en tu lengua materna.",
      people: {
        1: {
          name: "Andre Mastria",
          nationality: "Brasil",
        },
        3: {
          name: "Carla Oyarce",
          nationality: "Estados Unidos",
        },
        4: {
          name: "Kun Yang",
          nationality: "China",
        },
      },
    },
    methodology: {
      title: "Metodología",
      subtitle:
        "El objetivo principal de nuestros cursos en NL College es la comunicación.",
      desc: `<p>
          <strong>
            El objetivo de la gramática, pronunciación y vocabulario que aprendes con nosotros es ser capaz de comunicarte en español de la manera más efectiva posible.
          </strong>
        </p>`,
      items: {
        1: `NL College ofrece cursos de español de diferentes niveles marcados por el Marco Europeo Común de Referencia <a target="_blank" href="https://www.coe.int/en/web/common-european-framework-reference%20languages/level-descriptions">MCER</a> y descritos por el Instituto Cervantes y su Plan Curricular <a target="_blank" href="https://cvc.cervantes.es/ensenanza/biblioteca_ele/plan_curricular/default.htm"> PCIC.</a>`,
        2: "Hablarás español desde el primer día en clase. La metodología de enseñanza de NL College está basada en la filosofía actual de inmersión lingüística; aprender y enseñar totalmente en español. Todos los libros de texto y material de enseñanza están en español desde el principio.",
        3: "En NL College usamos libros de texto de español para extranjeros, seleccionados precisamente por su metodología moderna y completa. Son la serie “Nuevo Prisma” de la editorial Edinumen, de A1 a C2. El set de libros incluye un libro del estudiante, un libro de ejercicios y una extensión digital en la ELEteca con contenido y actividades que expanden el material como ejercicios complementarios, fichas y material audiovisual.",
        4: "Los cursos de español en NL College siguen el enfoque comunicativo, orientado en la acción y el estudiante. Se usan reflexiones gramaticales claras y accesibles con actividades para que el estudiante participe activamente en su aprendizaje.",
        5: "NL College proporciona estrategias de aprendizaje y comunicación para que los estudiantes reflexionen sobre su proceso de aprendizaje. Reflexión intercultural y acercamiento a la diversidad cultural del mundo hispánico.",
        6: "En NL College desarrollarás las diferentes actividades del lenguaje que incluyen comprensión, expresión, interacción o mediación siguiendo el MCER.At NL College you will develop the different activities of the language that includes comprehension, expression, interaction or mediation following the CEFR.",
        7: "Para NL College, la calidad de la enseñanza es lo más importante. Nuestro equipo de profesores está formado  únicamente por profesores nativos cualificados con mucha experiencia enseñando español a extranjeros. Todos nuestros profesores en NL College tienen una formación universitaria en lingüística del español con estudios especializados en ELE (Enseñanza del Español como Lengua Extranjera).",
      },
      button: "QUIERO ESTUDIAR EN BARCELONA",
    },
    levels: {
      button: "QUIERO ESTUDIAR EN BARCELONA",
    },
  },
  madrid: {
    header: {
      title: "Madrid",
    },
    campus: {
      title: "Nuestro Campus",
      desc: `<p class="text-medium">
          <b>Vas a estudiar en el corazón de Madrid.</b> Todo lo que necesitas está a tu alrededor: transporte público, hermosos puntos turísticos, todos los tipos de comida y ¡muchísima gente de todas partes del mundo!
        </p>
        <p class="text-medium">
          Madrid es la tercera ciudad más grande de la Unión Europea, después de Londres y Berlín. Aprende español a través del arte, los deportes, el ambiente acogedor y aprovecha la diversidad de todo lo que nos ofrece Madrid. ¡Es una jungla para explorar!
        </p>`,
      button: "QUIERO ESTUDIAR CON VOSOTROS",
      features: {
        1: "Ubicado en el <b>corazón de la ciudad</b>",
        2: "Profesores de español <b>nativos</b>",
        3: "Sala de <b>Estudiantes</b>",
      },
    },
    why_madrid: {
      title: "¿Por qué escoger Madrid?",
      desc: `<p>
              Madrid es la capital de España. 
              <b class="text-dark">
                Es la capital donde todo sucede.
              </b>
              Puedes explorar la ciudad usando el metro o aprovechar el Aeropuerto Internacional de Madrid que te puede llevar hasta 154 destinos diferentes. 
            </p>
            <p>
              Visita el Retiro, el parque más importante de Madrid, para ver la parte más verde de la ciudad. Ves a uno de los mercados de pescado más grandes del mundo o sácate una selfie en la Plaza Mayor y conoce más sobre la cultura de la ciudad. Madrid también celebra la segunda fiesta del Orgullo Gay más grande del mundo. Nuestra escuela quiere asegurarse que te lo pases genial en España, ya sea mejorando tus habilidades con el español o viviendo una nueva experiencia, ¡Tu camino empieza aquí! 
            </p>`,
      button: "QUIERO ESTUDIAR EN MADRID",
    },
    team: {
      title: "Equipo",
      subtitle:
        "Nuestro equipo está preparado para ayudarte antes y después de llegar a Madrid, contestando todas tus preguntas en tu lengua materna.",
      people: {
        1: {
          name: "Angélica",
          nationality: "Rusia",
        },
        2: {
          name: "Hirane",
          nationality: "Brasil",
        },
        3: {
          name: "Nishant",
          nationality: "India",
        },
        4: {
          name: "Mohamed",
          nationality: "Marruecos",
        },
        5: {
          name: "Sonia",
          nationality: "China",
        },
      },
    },
    methodology: {
      title: "Methodology",
      subtitle:
        "El objetivo principal de nuestros cursos en NL College es la comunicación.",
      desc: `<p>
          <strong>
            El objetivo de la gramática, pronunciación y vocabulario que aprendes con nosotros es ser capaz de comunicarte en español de la manera más efectiva posible.
          </strong>
        </p>`,
      items: {
        1: `NL College ofrece cursos de español de diferentes niveles marcados por el Marco Europeo Común de Referencia <a target="_blank" href="https://www.coe.int/en/web/common-european-framework-reference%20languages/level-descriptions">MCER</a> y descritos por el Instituto Cervantes y su Plan Curricular <a target="_blank" href="https://cvc.cervantes.es/ensenanza/biblioteca_ele/plan_curricular/default.htm"> PCIC.</a>`,
        2: "Hablarás español desde el primer día en clase. La metodología de enseñanza de NL College está basada en la filosofía actual de inmersión lingüística; aprender y enseñar totalmente en español. Todos los libros de texto y material de enseñanza están en español desde el principio.",
        3: "En NL College usamos libros de texto de español para extranjeros, seleccionados precisamente por su metodología moderna y completa. Son la serie “Nuevo Prisma” de la editorial Edinumen, de A1 a C2. El set de libros incluye un libro del estudiante, un libro de ejercicios y una extensión digital en la ELEteca con contenido y actividades que expanden el material como ejercicios complementarios, fichas y material audiovisual.",
        4: "Los cursos de español en NL College siguen el enfoque comunicativo, orientado en la acción y el estudiante. Se usan reflexiones gramaticales claras y accesibles con actividades para que el estudiante participe activamente en su aprendizaje.",
        5: "NL College proporciona estrategias de aprendizaje y comunicación para que los estudiantes reflexionen sobre su proceso de aprendizaje. Reflexión intercultural y acercamiento a la diversidad cultural del mundo hispánico.",
        6: "En NL College desarrollarás las diferentes actividades del lenguaje que incluyen comprensión, expresión, interacción o mediación siguiendo el MCER.At NL College you will develop the different activities of the language that includes comprehension, expression, interaction or mediation following the CEFR.",
        7: "Para NL College, la calidad de la enseñanza es lo más importante. Nuestro equipo de profesores está formado  únicamente por profesores nativos cualificados con mucha experiencia enseñando español a extranjeros. Todos nuestros profesores en NL College tienen una formación universitaria en lingüística del español con estudios especializados en ELE (Enseñanza del Español como Lengua Extranjera).",
      },
      button: "QUIERO ESTUDIAR EN MADRID",
    },
    levels: {
      button: "QUIERO ESTUDIAR EN MADRID",
    },
  },
  levels: {
    title: "Niveles",
    items: [
      {
        color: "primary",
        name: "C2",
        title: "Maestría",
        hours: "560",
        weeks: 28,
        courses: "C2.1, C2.2, C2.3, C2.4, C2.5, C2.6, C2.7 - ADVANCED",
        diploma: "Diploma DELE Oficial C2",
        details:
          "Serás capaz de entender, resumir y expresar fluidamente y con exactitud tus ideas, además de poder entender todo lo que lees o escuchas.",
      },
      {
        color: "primary",
        name: "C1",
        title: "Dominio",
        hours: "480",
        weeks: 24,
        courses: "C1.1, C1.2, C1.3, C1.4, C1.5 and C1.6 - ADVANCED",
        diploma: "Diploma DELE Oficial C1",
        details:
          "Tendrás la habilidad de entender textos más largos, conversaciones complejas y podrás expresarte sin usar palabras y expresiones obvias.",
      },
      {
        color: "tertiary",
        name: "B2",
        title: "Avanzado",
        hours: "400",
        weeks: 20,
        courses: "B2.1, B2.2, B2.3, B2.4 and B2.5 - INTERMEDIATE",
        diploma: "Diploma DELE Oficial B2",
        details:
          "Serás capaz de interactuar con hablantes nativos con más confianza y con una fluidez y espontaneidad considerables; tendrás la habilidad de desarrollar textos detallados sobre diferentes temas con facilidad.",
      },
      {
        color: "tertiary",
        name: "B1",
        title: "Umbral",
        hours: "120",
        weeks: 6,
        courses: "B1.1, B1.2, B1.3 and B1.4 - INTERMEDIATE",
        diploma: "Diploma DELE Oficial B1",
        details:
          "Serás capaz de viajar solo sin problemas con la lengua. Podrás describir eventos y sueños, además de desarrollar explicaciones racionales, opiniones y planes.",
      },
      {
        color: "secondary",
        name: "A2",
        title: "Plataforma",
        hours: "80",
        weeks: 4,
        courses: "A2.1, A2.2 and A2.3 - ELEMENTARY",
        diploma: "Diploma DELE Oficial A2",
        details:
          "Tendrás la habilidad de entender la mayoría de las expresiones y oraciones comunes de la mayoría de áreas de conocimiento (como información personal, descripción de familia y geografía local).",
      },
      {
        color: "secondary",
        name: "A1",
        title: "Acceso",
        hours: "80",
        weeks: 4,
        courses: "A1.1 and A1.2 - BEGINNER",
        diploma: "Diploma DELE Oficial A1",
        details:
          "Serás capaz de entender las expresiones diarias, responder preguntas personales detalladas y tendrás la habilidad de presentarte a ti mismo/misma.",
      },
    ],
    subtitle:
      "Tenemos cuatro programas principales: curso de español acelerado, intensivo, semi-intensivo, extensivo y, a parte, clases privadas. Podrás escoger el que más se adecúe a tus objetivos y estilo de vida. Cada dos semanas empieza un nuevo grupo de A1 y cada lunes puedes empezar en NL College; al final del curso, recibirás tu certificado.",
    hours: "horas",
    weeks: "semanas",
    courses: "Cursos",
    diploma: "Diploma DELE oficial:",
  },
  contactus: {
    title: "Contáctanos",
    subtitle: `En NL College te damos la mejor oportunidad para aprender español e inglés y ampliar tu potencial para seguir aprendiendo y construyendo tu carrera. ¡Contáctanos y prepárate para desarrollar tus habilidades personales y viajar por el mundo con confianza!`,
    campus: {
      title: "Nuestros Campus",
    },
    follow_us: "Síguenos",
  },
  contact_form: {
    title: "Contáctanos",
    first_name: "Nombre",
    last_name: "Apellido",
    email: "Email",
    phone: "Teléfono / Whatsapp",
    country: "País",
    nationality: "Nacionalidad",
    campus: "Campus preferido",
    message: "Mensaje",
    terms:
      "He leído y acepto la Política de Privacidad de NL College, los Términos y Condiciones y la Política de Cancelación.",
    button: "ENVIAR",
    success_msg: "Muchas gracias, su mensaje ha sido enviado",
    check_terms_error:
      "Debe aceptar los terminos y condiciones para enviar el mensaje",
  },
  accommodation: {
    title: "Vida de Estudiante",
    subtitle: "Alojamiento",
    desc: `Sabemos lo genial que es que alguien te apoye - especialmente cuando te mudas a un país extranjero - y por eso queremos proporcionarte el servicio de alojamiento, para facilitar el proceso administrativo. Estarás alojado/a en un lugar seguro, lo más cercano posible a la escuela para maximizar tu comodidad, gracias a nuestro sistema de colaboraciones con servicios de alojamiento.`,
    features: {
      title: "Elija la solución que más le convenga entre:",
      items: [
        {
          color: "secondary",
          title: "Residencias de estudiantes",
          img: "/imgs/student_life/accommodation/01.png",
          details: `Las residencias de estudiantes son perfectas para aquellos que quieren vivir rodeados de otros estudiantes, tanto locales como internacionales. Ofrecen instalaciones y facilidades  maravillosas, como vigilancia 24h o servicios de limpieza con todos los gastos de agua, luz, gas e internet incluidos, lo que hará tu vida más fácil. Los estudiantes tienen acceso a su habitación y comparten otras áreas como salas de estudio, zonas chill-out e incluso gimnasios. En estas zonas, conocerás otras culturas y ampliarás tu círculo social.`,
        },
        {
          color: "tertiary",
          title: "Piso compartido",
          img: "/imgs/student_life/accommodation/02.png",
          details: `Compartir un piso con otros estudiantes es un buen compromiso si necesitas más independencia. Esta opción te dará la oportunidad de organizarte como quieras mientras compartes espacios comunes (cocina, comedor, salón…) mientras mantienes tu propia habitación. Otro punto positivo es que tener compañeros de piso te da la oportunidad de desarrollar tu círculo social en Barcelona y Madrid - ¡Todos estaréis en el mismo barco!`,
        },
        {
          color: "primary",
          title: "Hostal",
          img: "/imgs/student_life/accommodation/03.png",
          details: `Una opción para aquellos que quieren más interacción con la comunidad internacional que vive en Barcelona y Madrid. Además de todas las instalaciones y facilidades que ofrece el hostal, la facilidad de conocer gente nueva y hacer amigos es un punto añadido.`,
        },
      ],
      button: "QUIERO HABLAR CON UN AGENTE",
    },
  },
  visa: {
    title: "Vida de Estudiante",
    subtitle: "Visado",
    desc: `NL College está preparado para ayudarte durante todo el proceso. Nuestro equipo plurilingüe te guiará en este proceso clave del intercambio. Estamos disponibles para contestar cualquier pregunta y la escuela proporciona toda la documentación necesaria.`,
    features: {
      title: `Hay tres opciones para el visado de estudiante, dependiendo de la duración de tu curso de español. Son:`,
      items: [
        {
          color: "primary",
          img: "/imgs/visa-02.png",
          title: "Visado de turista hasta",
          days: "90 días",
          category: "CATEGORÍA - C",
        },
        {
          color: "secondary",
          img: "/imgs/visa-03.png",
          title: "Visado de Estudiante hasta",
          days: "180 días",
          category: "CATEGORÍA - D",
        },
        {
          color: "tertiary",
          img: "/imgs/visa-04.png",
          title: "Visado de Estudiante extendido de",
          days: "180 días hasta 1 año",
          category: "CATEGORÍA - D",
        },
      ],
      subtitle: `Puedes enviar la solicitud para tu visado en el consulado español de tu país de residencia; la documentación requerida para cada proceso puede cambiar según el territorio. Si escoges solicitar tu visado en España, deberás entrar como turista y luego solicitar el permiso de estudiante. La documentación básica requerida es:`,
      requirements: `
        
        
        
        
        
        
        
        
        

          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Pasaporte válido durante todo el período de tu estancia.
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Razón válida para tu estancia (prueba de alojamiento, prueba de viaje y ticket de vuelta). 
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Prueba de medios económicos suficientes para todo el período de tu estancia. 
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Prueba de inscripción en clases de español (proporcionado por NL College). 
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Prueba de alojamiento.
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Prueba de pago completo del curso.
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Confirmación del registro en la escuela.
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Seguro médico de cobertura completa.
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Confirmación de alojamiento (contrato de alquiler o invitación).
            </p>
          </li>
        `,
      desc: `
          <p class="mb-5">
            <b class="lh-4">
              Si prefieres un apoyo más personalizado, el Departamento Legal de NL College te ofrece un nuevo y exclusivo servicio que no encontrarás en ninguna otra escuela de español. Nuestro equipo de abogados estudiarán tu caso personalizado y te acompañarán a través del proceso. De esta manera, te garantizamos una gran probabilidad de conseguir tu visado.
            </b>
          </p>
          <p class="lh-4">
            Te ofrecemos la posibilidad de gestionar y llevar el proceso de tu visado sin preocuparte de nada. Solo tendrás que recopilar toda la documentación que nuestros abogados especializados en extranjería y en visado necesiten. Gestionaremos el proceso del visado por ti.
          </p>
        `,
      button: "QUIERO HABLAR CON UN AGENTE",
    },
  },
  healthinsurance: {
    title: "Vida de Estudiante",
    subtitle: "Seguro Médico",
    desc: `Si tu visado de estudiante es de larga duración, deberás proporcionar un seguro médico con cobertura completa. Esto también se aplica a aquellos estudiantes que pretenden quedarse más de 90 días.`,
    features: {
      title: `En NL College nos preocupa la seguridad de nuestros estudiantes y por eso queremos ofrecer el mejor seguro y la mayor tranquilidad durante su experiencia en España.`,
      desc: `<p class="mb-4 text-medium lh-5">
            Nuestra institución está a cargo de proporcionar todo lo necesario para que el estudiante no se preocupe por nada. Si la razón es la gestión del visado o simplemente porque quieres estar cubierto en todos los aspectos médicos mientras te sumerges en el aprendizaje de la lengua, NL College se preocupa de todos los procesos administrativos y burocráticos con el objetivo y la seguridad de proporcionar la mayor calidad.
          </p>
          <p class="text-medium lh-5">
            Colaboramos con algunas de las compañías de seguros más grandes de España, con el propósito de ofrecer a los estudiantes garantías seguras.
          </p>`,
      subtitle: "PRECIOS DEL SEGURO MÉDICO",
      table: `<table class="header-primary body-medium">
            <thead>
              <tr>
                <th>MESES</th>
                <th>€</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>3 meses</td>
                <td>235.00€</td>
              </tr>
              <tr>
                <td>6 meses</td>
                <td>370.00€</td>
              </tr>
              <tr>
                <td>8 meses</td>
                <td>460.00€</td>
              </tr>
              <tr>
                <td>12 meses</td>
                <td>650.00€</td>
              </tr>
            </tbody>
          </table>`,
      button: "QUIERO HABLAR CON UN AGENTE",
    },
  },
  exams: {
    title: "Vida de Estudiante",
    subtitle: "Exámenes",
    desc: `Nos enorgullece que NL College sea un centro oficial de examen para los exámenes de DELE y CCSE. Nuestra misión es seguir mejorando para ofrecer mejores servicios cada día, si es posible.`,
    features: {
      title: `Ambos exámenes reconocen a nivel internacional el conocimiento de la lengua española a través de diferentes tests: comprensión lectora, comprensión auditiva, expresión escrita e interacción y expresión oral e interacción.`,
      items: [
        {
          logo: "/imgs/logo-dele.png",
          desc: `<p class="mb-0">El reconocimiento de los diplomas DELE como una certificación de competencia lingüística en español es internacional, en instituciones y autoridades públicas y privadas, además de en el mundo corporativo y cámaras de comercio. Los diplomas DELE son otorgados por el Instituto Cervantes, de parte del Ministerio de Educación, Cultura y Deportes de España; no tienen fecha de caducidad. Ofrecen exámenes desde el nivel A1 hasta el C2 del MCER y debes decidir con antelación de cuál te quieres examinar.</p>`,
        },
        {
          logo: "/imgs/logo-siele.png",
          desc: `<p>
                SIELE es el Servicio Internacional de Evaluación de la Lengua Española que certifica el nivel de competencia del español a través de plataformas electrónicas para estudiantes y profesionales de los cinco continentes. Es un examen oficial internacional reconocido de la lengua española.
              </p>
              <p class="mb-0">
                Lo promueve el Instituto Cervantes, la Universidad Nacional Autónoma de México, la Universidad de Salamanca y la Universidad de Buenos Aires, quienes garantizan los estándares de calidad y las buenas prácticas en la elaboración de los exámenes y el uso de las diferentes variedades del mundo hispano.
              </p>`,
        },
      ],
      button: "QUIERO HABLAR CON UN AGENTE",
    },
  },
  extraactivities: {
    title: "Vida de Estudiante",
    subtitle: "Actividades Extra",
    desc: `Como parte de nuestro currículum de actividades, ofrecemos actividades diarias y clases extracurriculares a nuestros estudiantes para animarlos a vivir la cultura española, tan rica y con tanto para ofrecer. Aprovechando que ambos campus están ubicados en ciudades conocidas alrededor del mundo, organizamos actividades específicas para los estudiantes para que puedan moverse en la cultura local y también excursiones para expandir su experiencia durante este período de estudio.`,
    monthly: {
      title: "Actividades y excursiones mensuales",
      subtitle: `La 
          <strong class="text-secondary">
            escuela organiza excursiones durante todo el año,
          </strong>
          así los estudiantes pueden descubrir las fiestas tradicionales españolas y, con ellas, pueden sumergirse en la cultura española. Desde clases magníficas de flamenco hasta probar paellas inolvidables o incluso ir a tomar tapas después de clase, nos centramos en dar a nuestros estudiantes la mejor experiencia posible.`,
    },
    quarterly: {
      title: "Actividades trimestrales y excursiones",
      subtitle: `
          <b class="text-tertiary">
          Cada trimestre habrá una excursión especial,
          </b>, que puede ser fuera de España, coincidiendo con el final del curso estipulado en el programa académico. El objetivo es que compartas un viaje con tus compañeros de clase, para que juntos podáis vivir una experiencia inolvidable.
          `,
    },
    extra: {
      title: "Clases extracurriculares",
      items: [
        {
          img: "/imgs/extra-activities-05.png",
          title: "Entrevista de trabajo",
          desc: `Sabemos lo difícil que puede ser encontrar un trabajo en un país extranjero y queremos dar a nuestros estudiantes todas las herramientas para conseguir su objetivo. Con una clase de preparación semanal para entrevistas de trabajo, el estudiante podrá desarrollar las habilidades de conversación para sentirse seguro de sí mismo durante el proceso.`,
        },
        {
          img: "/imgs/extra-activities-06.png",
          title: "Taller de Catalán",
          desc: `Los catalanes tienen mucho respeto a sus raíces; para poder sumergir a nuestros estudiantes en la cultura catalana, ofrecemos talleres de catalán que ayudan a los estudiantes a desarrollar sus habilidades lingüísticas mientras interactúan con los demás.`,
        },
        {
          img: "/imgs/extra-activities-07.png",
          title: "Cultura e Historia de Barcelona y Madrid",
          desc: `Una clase específica para cada campus para acercar al estudiante a la ciudad donde vive. Queremos que nuestros estudiantes conozcan los espacios más importantes que la ciudad ofrece, al mismo tiempo que los entienden de la misma manera que a los locales. El estudiante practicará la competencia lectora, comunicativa, auditiva y la redacción en un ambiente dinámico y relajado que añadirá valor cultural a su experiencia.`,
        },
        {
          img: "/imgs/extra-activities-08.png",
          title: "Club de Lectura",
          desc: `Esta actividad anima a leer, debatir y aprender de un libro. De esta manera, se alienta a leer y a desarrollar la crítica constructiva que incita a generar debates con las ideas de los demás. Conversación, comprensión, lectura y, lo mejor de todo, compartir tiempo con tus compañeros de clase y pasar un buen rato juntos.`,
        },
      ],
      button: "QUIERO SER UN ESTUDIANTE",
    },
  },
  spanishcourses: {
    title: "Cursos",
    sections: [
      {
        title: "Cursos de español",
        children: [
          {
            title: "Curso de español acelerado",
            description: `
              <p>
                El curso más popular y conocido en nuestra escuela para aprender a un ritmo rápido, específicamente diseñado para estudiantes de larga duración, cumple todos los requisitos para obtener el visado. 
              </p>
              <p>
                Los cursos son para aquellos estudiantes que quieren mejorar su comunicación, comprensión, escritura y gramática en un período corto de tiempo. 
              </p>
              <p>
                El curso intensivo consiste en 4 horas al día, lo que significa, 20 horas por semana y dos semanas por curso. Tenemos la posibilidad de unirse a nuestros cursos acelerados cada lunes durante todo el año.
              </p>
              `,
            details: `<ul>
                <li><b>20 clases:</b> a la semana</li>
                <li><b>4 clases diarias:</b> Lunes a Viernes.</li>
                <li><b>Duración de la clase:</b> Cada clase dura 55 minutos.</li>
                <li>
                  <b>Horario:</b> <br />
                  <ul class="disc-style">
                    <li>9.30am a 1.30pm - Lunes a Viernes.*</li>
                    <li>2pm a 6pm - Lunes a Viernes.*</li>
                  </ul>
                </li>
                <li><b>Fechas de inicio:</b> Todos los lunes.</li>
                <li><b>Niveles:</b> A1, A2, B1, B2, C1, C2.</li>
                <li>Un nivel en cuatro semanas**</li>
              </ul>`,
            price_details: `
                <div class="bg-white b-radius-3 p-4 text-center mt-4">

                  <p>Contáctanos para obtener un mejor precio, porque cuantas más semanas estudies, más descuentos obtendrás.</p>

                  <p>
                    Para un curso de larga duración de 27 semanas o más, tenemos una oferta especial para ti. <br>
                    Contáctanos para más información.
                  </p>
                </div>
              `,

            small_details:
              "*dependiendo de la disponibilidad / **pregunta para más información",
            button: "QUIERO SABER MÁS",
          },
          {
            title: "Curso de español semi-intensivo",
            description: `
              <p>
                Este curso está dirigido a esos estudiantes que tienen un tiempo limitado para invertir en estudiar español o para aquellos que quieren combinarlo con su trabajo u otros estudios. Proporcionamos un buen grupo dinámico y son clases más económicas que las clases individuales.
              </p>
              <p>
                El curso consiste en 10 horas a la semana, lo que significa 2 horas al día, que te permite crear un proceso constante en el aprendizaje de la lengua. 
              </p>
              `,
            details: `<ul>
                <li><b>10 clases:</b> a la semana</li>
                <li><b>2 clases diarias:</b> Lunes a Viernes.</li>
                <li><b>Duración de la clase:</b> Cada clase dura 55 minutos.</li>
                <li>
                  <b>Horario:</b> <br />
                  <ul class="disc-style">
                    <li>2pm a 4pm - Lunes a Viernes.*</li>
                    <li>5pm a 7pm - Lunes a Viernes.*</li>
                  </ul>
                </li>
                <li><b>Fechas de inicio:</b> Todos los lunes.</li>
                <li><b>Niveles:</b> A1, A2, B1, B2, C1, C2.</li>
                <li>Un nivel en 8 semanas**</li>
              </ul>`,
            price_details: `
                <div class="bg-white b-radius-3 p-4 text-center mt-4">

                  <p>Contáctanos para obtener un mejor precio, porque cuantas más semanas estudies, más descuentos obtendrás.</p>
                </div>
              `,

            small_details:
              "*dependiendo de la disponibilidad / **pregunta para más información",
            button: "QUIERO SABER MÁS",
          },
          {
            title: "Clases por la tarde",
            description: `
              <p>
                Nuestras clases de español por la tarde son perfectas para estudiantes con trabajos a tiempo completo, estudios universitarios u otras responsabilidades durante la semana.
              </p>
              <p>
                Las clases de español por la tarde tienen lugar dos veces a la semana, 2 horas al día, ya sea el lunes y el miércoles o el martes y el jueves, de 18.30 a 20.30, proporcionándote tiempo suficiente para trabajar, estudiar o disfrutar de la ciudad durante el día mientras sigues manteniendo un aprendizaje constante.
              </p>
              `,
            details: `<ul>
                <li><b>4 clases:</b> a la semana</li>
                <li><b>2 clases diarias:</b> Lunes y Miercoles o Martes y Jueves.</li>
                <li><b>Duración de la clase:</b> Cada clase dura 55 minutos</li>
                <li>
                  <b>Horario:</b> <br />
                  <ul class="disc-style">
                    <li>6.30pm a 8.30pm</li>
                  </ul>
                </li>
                <li><b>Fechas de Inicio:</b> Todas las semanas.</li>
                <li><b>Niveles:</b> A1, A2, B1, B2, C1, C2.</li>
                <li>Un nivel en 10 semanas*</li>
              </ul>`,
            price_details: `
                <div class="bg-white b-radius-3 p-4 text-center mt-4">

                  <p>Contáctanos para obtener un mejor precio, porque cuantas más semanas estudies, más descuentos obtendrás.</p>
                </div>
              `,

            small_details: "*pregunta para más información",
            button: "QUIERO SABER MÁS",
          },
        ],
      },
      {
        title: "Clases Privadas",
        children: [
          {
            title: "Uno y uno / Dos y uno / Tres y uno",
            description: `
              <p>
                Flexibles y basándose en tus necesidades, el profesor se adaptará a tu estilo y objetivos de aprendizaje del español. Tendrás el horario que quieras, sin importar si estás disponible por la mañana o por la tarde, a cualquier hora, cualquier día, sólo háznoslo saber y lo haremos por ti.
              </p>
              <p>
                Puede ser para una persona o un grupo pequeño hasta 3 personas, nuestras clases están impartidas por profesores nativos con experiencia en enseñar español a extranjeros. Las clases podrán ser en persona en la escuela u ofrecemos la opción de desplazarnos allí donde estés, en tu casa o tu trabajo.
              </p>
              <p>
                También puedes escoger la opción de clases privadas virtuales, que tienen el mismo precio que las presenciales, pero usando la plataforma de videoconferencia, Zoom.
              </p>
              `,
            details: null,
            price_details: null,
            small_details: null,
            button: "QUIERO SABER MÁS",
          },
        ],
      },
    ],
    button: "QUIERO ESTUDIAR CON VOSOTROS",
  },
  englishcourses: {
    title: "Cursos de Inglés",
    desc: `
    <p>Si quieres seguir desarrollando tu vida profesional y personal, escoge nuestros cursos de inglés. Con horarios flexibles siempre adaptados a tus necesidades y clases dinámicas con profesores nativos.</p>
    <br>
    <p><b>Prepárate para el siguiente paso en tu vida.</b></p>`,
    courses: {
      semi_intensive: {
        title: "Curso de inglés semi-intensivo",
        details: `<ul>
            <li><b>10 horas a la semana:</b> De Lunes a Viernes</li>
            <li><b>2 horas diarias</b></li>
            <li><b>Duración de las clases:</b> 55 minutos por lección.</li>
            <li>
              <b>Horario:</b> <br />
              <ul class="disc-style">
                <li>9.30h a 11.30h - de Lunes a Viernes.</li>
              </ul>
            </li>
            <li><b>Inicio de clases:</b> Cada semana.</li>
            <li><b>Niveles:</b> A1, A2, B1, B2, C1, C2.</li>
            <li>1 nivel en 8 semanas**</li>
          </ul>`,
        button: "QUIERO SABER MÁS",
      },
      afternoon: {
        title: "Curso de inglés de tarde",
        details: `<ul>
            <li><b>4 horas a la semana:</b> Lun-Miér o Mar-Jue</li>
            <li><b>2 horas diarias</b></li>
            <li><b>Duración de las clases:</b> 55 minutos por lección.</li>
            <li>
              <b>Horario:</b> <br />
              <ul class="disc-style">
                <li>18.30h a 20.30h</li>
              </ul>
            </li>
            <li><b>Inicio de clases:</b> Cada semana.</li>
            <li><b>Niveles:</b> A1, A2, B1, B2, C1, C2.</li>
            <li>1 nivel en 10 semanas**</li>
          </ul>`,
        button: "QUIERO SABER MÁS",
      },
      private: {
        title: "Clases privadas",
        details: `<ul>
            <li>Flexibles y adaptadas a tus necesidades, las clases pueden ser en persona en nuestra escuela, en línea usando la plataforma ZOOM o podemos movernos donde tú estés, ya sea en tu propia casa, una cafetería o tu lugar de trabajo.</li>
          </ul>`,
        button: "QUIERO SABER MÁS",
      },
    },
    button: "QUIERO ESTUDIAR CON VOSOTROS",
  },
  privacypolicy: {
    title: "Política de privacidad",
    content: `<h4 class="mb-4 text-primary">POLÍTICA DE PRIVACIDAD REDES SOCIALES</h4>
        <p>
          En cumplimiento de la normativa vigente en Protección de Datos de
          Carácter Personal (RGPD) y la Ley 34/2002, de 11 de julio, de
          Servicios de la Sociedad de la Información y de Comercio Electrónico
          (LSSI-CE), NATIVE LANGUAGE COLLEGE informa a los usuarios, que ha
          procedido a crear un perfil en las Redes Sociales Facebook, Twitter,
          Instagram, Linkedin y Google +, con la finalidad principal de
          publicitar sus productos y servicios.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Datos de NATIVE LANGUAGE COLLEGE:</h4>
        <ul class="mb-4 disc-style">
          <li>CIF: B87792370</li>
          <li>DIRECCIÓN: PLAZA PUERTA DEL SOL, 11 2ª 28013, MADRID</li>
          <li>CORREO ELECTRÓNICO: info@nlcollege.es</li>
          <li>DOMINIO WEB: www.nlcollege.es</li>
        </ul>
        <p>
          El usuario dispone de un perfil en la misma Red Social y ha decidido
          unirse a la página creada por NATIVE LANGUAGE COLLEGE, mostrando así
          interés en la información que se publicite en la Red. Al unirse a
          nuestra página, nos facilita su consentimiento para el tratamiento de
          aquellos datos personales publicados en su perfil.
        </p>
        <p>
          El usuario puede acceder en todo momento a las políticas de privacidad
          de la propia Red Social, así como configurar su perfil para garantizar
          su privacidad.
        </p>
        <p>
          NATIVE LANGUAGE COLLEGE tiene acceso y trata aquella información
          pública del usuario, en especial, su nombre de contacto. Estos datos,
          sólo son utilizados dentro de la propia Red Social. No son
          incorporados a ningún sistemas de tratamiento.
        </p>
        <p>
          En relación a los derechos de acceso, rectificación, limitación de
          tratamiento, supresión, portabilidad y oposición al tratamiento de sus
          datos de carácter personal, de los que usted dispone y que pueden ser
          ejercitados ante NATIVE LANGUAGE COLLEGE, de acuerdo con la RGPD, debe
          tener en cuenta los siguientes matices:
        </p>
        <ul class="mb-4 disc-style">
          <li>
            Derecho de Acceso: Es el derecho del usuario a obtener información
            sobre sus datos concretos de carácter personal y del tratamiento que
            se haya realizado o realice, así como de la información disponible
            sobre el origen de dichos datos y las comunicaciones realizadas o
            previstas de los mismos.
          </li>
          <li>
            Derecho de Rectificación: Es el derecho del afectado a que se
            modifiquen los datos que resulten ser inexactos o incompletos. Sólo
            podrá satisfacerse en relación a aquella información que se
            encuentre bajo el control de NATIVE LANGUAGE COLLEGE, por ejemplo,
            eliminar comentarios publicados en la propia página, imágenes o
            contenidos web donde consten datos de carácter personal del usuario.
          </li>
          <li>
            Derecho a la Limitación de tratamiento: Es el derecho a que se
            limiten los fines del tratamiento previstos de forma original por el
            responsable del tratamiento.
          </li>
          <li>
            Derecho de Supresión: Es el derecho a suprimir los datos de carácter
            personal del usuario, a excepción de lo previsto en el propio RGPD o
            en otras normativas aplicables que determinen la obligatoriedad de
            la conservación de los mismos, en tiempo y forma.
          </li>
          <li>
            Derecho de portabilidad: El derecho a recibir los datos personales
            que el usuario, haya facilitado, en un formato estructurado, de uso
            común y lectura mecánica, y a transmitirlos a otro responsable.
          </li>
          <li>
            Derecho de Oposición: Es el derecho del usuario a que no se lleve a
            cabo el tratamiento de sus datos de carácter personal o se cese el
            tratamiento de los mismos por parte de NATIVE LANGUAGE COLLEGE.
          </li>
        </ul>
        <h4 class="mb-4 mt-5 text-dark">
          NATIVE LANGUAGE COLLEGE realizará las siguientes actuaciones:
        </h4>
        <ul class="mb-4 disc-style">
          <li>Acceso a la información pública del perfil.</li>
          <li>
            Publicación en el perfil del usuario de toda aquella información ya
            publicada en la página de
          </li>
        </ul>
        <h4 class="mb-4 mt-5 text-dark">NATIVE LANGUAGE COLLEGE.</h4>
        <ul>
          <li>
            Enviar mensajes personales e individuales a través de los canales de
            la Red Social.
          </li>
          <li>
            Actualizaciones del estado de la página que se publicarán en el
            perfil del usuario.
          </li>
        </ul>
        <p>
          El usuario siempre puede controlar sus conexiones, eliminar los
          contenidos que dejen de interesarle y restringir con quién comparte
          sus conexiones, para ello deberá acceder a su configuración de
          privacidad.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Publicaciones</h4>
        <p>
          El usuario, una vez unido a la página de NATIVE LANGUAGE COLLEGE,
          podrá publicar en ésta última comentarios, enlaces, imágenes o
          fotografías o cualquier otro tipo de contenido multimedia soportado
          por la Red Social. El usuario, en todos los casos, debe ser el titular
          de los mismos, gozar de los derechos de autor y de propiedad
          intelectual o contar con el consentimiento de los terceros afectados.
          Se prohíbe expresamente cualquier publicación en la página, ya sean
          textos, gráficos, fotografías, vídeos, etc. que atenten o sean
          susceptibles de atentar contra la moral, la ética, el buen gusto o el
          decoro, y/o que infrinjan, violen o quebranten los derechos de
          propiedad intelectual o industrial, el derecho a la imagen o la Ley.
          En estos casos, NATIVE LANGUAGE COLLEGE se reserva el derecho a
          retirar de inmediato el contenido, pudiendo solicitar el bloqueo
          permanente del usuario.
        </p>
        <p>
          NATIVE LANGUAGE COLLEGE no se hará responsable de los contenidos que
          libremente ha publicado un usuario.
        </p>
        <p>
          El usuario debe tener presente que sus publicaciones serán conocidas
          por los otros usuarios, por lo que él mismo es el principal
          responsable de su privacidad.
        </p>
        <p>
          Las imágenes que puedan publicarse en la página no serán almacenadas
          en ningún sistemas de tratamiento por parte de NATIVE LANGUAGE
          COLLEGE, pero sí que permanecerán en la Red Social.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Concursos y promociones</h4>
        <p>
          NATIVE LANGUAGE COLLEGE se reserva el derecho a realizar concursos y
          promociones, en los que podrá participar el usuario unido a su página.
          Las bases de cada uno de ellos, cuando se utilice para ello la
          plataforma de la Red Social, serán publicadas en la misma. Cumpliendo
          siempre con la LSSI-CE y con cualquier otra norma que le sea de
          aplicación. La Red Social no patrocina, avala ni administra, de modo
          alguno, ninguna de nuestras promociones, ni está asociada a ninguna de
          ellas.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Publicidad</h4>
        <p>
          NATIVE LANGUAGE COLLEGE utilizará la Red Social para publicitar sus
          productos y servicios, en todo caso, si decide tratar sus datos de
          contacto para realizar acciones directas de prospección comercial,
          será siempre, cumpliendo con las exigencias legales de la RGPD y de la
          LSSI-CE.
        </p>
        <p>
          No se considerará publicidad el hecho de recomendar a otros usuarios
          la página de NATIVE LANGUAGE COLLEGE para que también ellos puedan
          disfrutar de las promociones o estar informados de su actividad.
        </p>
        <p>
          A continuación detallamos el enlace a la política de privacidad de la
          Red Social:
        </p>
        <ul class="mb-4 disc-style">
          <li>Facebook: https://www.facebook.com/help/323540651073243/</li>
          <li>Twitter: https://twitter.com/privacy</li>
          <li>Instagram: http://instagram.com/about/legal/privacy/</li>
          <li>
            Linkedin:
            http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv
          </li>
          <li>Google +: http://www.google.com/intl/es/policies/privacy/</li>
        </ul>`,
  },
  price_list: {
    title: "Vida de Estudiante",
    subtitle: "Lista de Precios",
    desc: {
      title: "Precios del Curso",
      description: `
        ¡Nuestra escuela está lista para recibirte! Estamos encantados de ofrecer los precios más competitivos, con un balance excepcional entre calidad, instalaciones únicas y experiencia de inmersión cultural en el español.
        <br> <br>
        Con opciones de cursos: Acelerado (20 horas semanales), Semi-Intensivo (10 horas semanales) o Extensivo (4 horas semanales). Elige el que mejor se adapte a tus necesidades,
        <br> <br>
        Tenemos distintas opciones que cubrirán tus necesidades en cuanto a el número de semana, horarios, y duración de nuestros cursos. Prepárate para dar el salto al siguiente nivel en tu vida.`,
      tip: "Consejo: para obtener la mejor relación calidad-precio, consulta nuestras opciones de paquetes disponibles a continuación.",
    },
    table: {
      weeks: "SEMANAS",
      week: "semana",
      accelerated: "ACELERADO",
      semi_intensive: "SEMI-INTENSIVO",
      extensive: "EXTENSIVO",
    },
    private: {
      title: "Clases Privadas",
      description: `
        Flexibles y de acuerdo a tus necesidades, el profesor se adaptará a tu estilo de aprendizaje y objetivos específicos de la lengua española. Nos acomodamos al horario que desees, no importa si estás disponible por la mañana o por la tarde, a cualquier hora y cualquier día, sólo háganoslo saber y nos acomodaremos a tu ritmo.
        <br><br>
        Las clases pueden ser para una persona o un grupo pequeño de hasta 3, donde podrás dividir el costo entre tus amigos y ahorrar más dinero.
        <br><br>
        Nuestras clases privadas serán presenciales en la escuela o también ofrecemos la opción de ir a donde tú estés, ya sea en tu casa o lugar de trabajo.
        <br><br>
        Igualmente podrás elegir clases privadas virtuales, que tienen el mismo precio que las clases particulares presenciales, pero utilizando la plataforma de videoconferencia zoom.`,
      items: {
        1: "Cuota de inscripción: € 40 - Única vez",
        2: "Examen SIELE + Preparación: € 190",
        3: "Libro de Trabajo € 25 / Libro de Gramática € 25",
      },
    },
    table2: {
      title: "CLASES PRIVADAS: 90 minutos (Precios por persona y hora)",
      hours_pack: "Paquetes de Horas",
      hours_a: "De 1,5 a 10 hrs",
      hours_b: "De 10 a 20 hrs",
      hours_c: "De 21 a 50 hrs",
      hours_d: "Afuera",
      add: "Agregar",
    },
    special_packages: {
      title: "Paquetes Especiales",
      description: `Si lo que buscas son cursos de larga duración para así obtener tu visado por estudios, aquí encontrarás las mejores opciones.
        <br><br>
        Todos nuestros paquetes incluyen:`,
      items: {
        1: "Matrícula de incorporación",
        2: "Todos los libros que usarás en el curso",
        3: "App exclusiva para los estudiantes",
        4: "Curso de preparación de Siele",
        5: "Examen Siele",
        6: "Actividades de inmersión cultural",
        7: "Kit de bienvenida",
      },
      description_2: "¡No te preocupes por nada más que aprender español!",
    },
    long_term: {
      title: "PAQUETE Acelerado Larga Duración (20h semanales)",
      subtitle:
        "Cuota de inscripción + Todos los libros incluidos + App + Actividades extra + Kit de bienvenida + Curso de preparación SIELE + Examen",
      weeks: "Semanas",
      holidays: "Vacaciones",
      months: "Meses",
      price: "Precio",
    },
    dele_exam: {
      title: "Curso de Preparación DELE",
      description: `
        DELE está organizado por el Instituto Cervantes y es un diploma reconocido internacionalmente en el dominio del idioma español.
        <br><br>
        Este es el examen más importante para acreditar oficialmente tu nivel de español. A nivel mundial, te abrirá muchas puertas, tanto en el ámbito profesional así como también con la admisión a muchas Universidades Españolas. 
        <br><br>
        Prepárate para el examen con nuestro curso de preparación DELE.`,
    },
    dele_table: {
      title: "Curso de preparación DELE (20h semanales)",
      subtitle: "Cuota de inscripción y libro incluidos",
      weeks: "Semanas",
    },
    extras: {
      title: "Servicios adicionales",
      table1: {
        title: "Alojamiento",
        items: [
          { label: "Residencia de Estudiantes", value: "Desde € 650/mes" },
          { label: "Piso Compartido", value: "Desde € 400/mes" },
          { label: "Hostal", value: "Desde € 145/semana" },
        ],
      },
      table2: {
        title: "Establecerse",
        items: [
          { label: "Recogida en aeropuerto", value: "Desde € 65" },
          { label: "Soporte para la solicitud de Visa", value: "€ 400" },
          { label: "Establecer Visa en España", value: "€ 110" },
        ],
      },
    },
    button: "QUIERO SER ESTUDIANTE",
  },
  placement_test: {
    title: "Prueba de nivel",
    desc: `
      <b>La función principal de esta prueba es evaluar tu dominio del idioma.</b> <br> <br>
      La prueba determinará tu nivel de competencia general desde el Nivel 1 (Principiante) hasta el Nivel 5 (Avanzado).
    `,
    instructions: {
      desc: `
        En NL College nos esforzamos para garantizar que todos nuestros estudiantes se ubiquen en el nivel 
        adecuado, para asegurar que tengan un buen progreso en su curso.
      `,
      title: "Instrucciones",
      items: [
        "Hay 60 preguntas de opción múltiple",
        "Cada pregunta evalúa una característica de la estructura del inglés",
        "Las estructuras gramaticales varían de simples a complejas",
        "Lee las preguntas y marca una de las opciones. Solo hay una respuesta correcta para cada pregunta",
        "Reserva aproximadamente 45 minutos para completar la prueba",
        'Las preguntas se vuelven más difíciles a medida que avanza la prueba.Si las preguntas se vuelven demasiado difíciles, ve al final de la prueba y haz clic en el botón "Finalizar"',
      ],
    },
    thanks: "¡Muchas gracias por tu tiempo y buena suerte!",
    button: "EMPEZAR LA PRUEBA",
    link: "https://forms.gle/bA9GXVU2ZyoyAaRq8",
  },
  learnenglish: {
    contactus: "CONTÁCTANOS",
    title: "AHORA ES EL MOMENTO <br /> DE APRENDER INGLÉS",
    subtitle:
      "Que no se te escapen más oportunidades. <br /> Estudiando con NL COLLEGE, podrás:",
    head_items: [
      "Convertirte en el candidato perfecto y asegurarte el puesto que desees",
      "Alcanzar el salario que te mereces",
      "Elegir donde quieres vivir, sin limitaciones",
      "Viajar y experimentar tu destino como un local",
    ],
    form: {
      title: "¡Empieza ahora!",
      subtitle: "No pierdas esta gran oportunidad",
      name: "Nombre completo",
      email: "Correo Electrónico",
      phone: "Teléfono",
      nationality: "Nacionalidad",
      button: "ADELANTE",
      success_msg:
        "Muchas gracias, ¡Estás un paso más cerca de empezar a aprender inglés de una vez por todas!",
    },
    whylearn: {
      title: "Por qué aprender inglés",
      testimonials: [
        {
          img: "/imgs/landing_0323/alba.png",
          name: "Alba P (España)",
          linkedin: "https://www.linkedin.com/in/disisaruba/",
          comments: `
            Hay muchas personas con un perfil laboral similar al nuestro. Es
            una realidad. Pero hay elementos que cosiguen diferenciarnos de
            los demás y, en muchos casos, es saber un idioma. Sé que, si no
            hubiera podido hablar ni escribir inglés con fluidez, incluso
            habiendo pasado la prueba durante el proceso de selección, no
            habría conseguido mi trabajo actual.
          `,
        },
        {
          img: "/imgs/landing_0323/mateo.png",
          name: "Mateo G (Argentina)",
          linkedin: "https://www.linkedin.com/in/mateo-gastaldi-b53687134/",
          comments: `
            Con el inglés tuve la oportunidad de conseguir trabajos en
            empresas internacionales, ampliar mi red de networking y contactos
            a personas de todo el mundo. ¡Además, puedo viajar libremente sin
            tener miedo, ya que me puedo comunicar en cualquier parte del
            globo!
          `,
        },
        {
          img: "/imgs/landing_0323/carla.png",
          name: "Carla O (Chile)",
          linkedin:
            "https://www.linkedin.com/in/carla-oyarce-barbieri-983227249/",
          comments: `
            Hablar inglés te abre muchísimas puertas. Personalmente, gracias
            al inglés he podido vivir en diferentes partes del mundo,
            trabajar, hacer amigos de todas las nacionalidades y desarrollarme
            profesionalmente en cualquier lugar. Puedes crear lazos
            maravillosos y abrir tu mundo porque te ayuda al entendimiento, la
            aceptación y el respeto de la diversidad del mundo.
          `,
        },
      ],
    },
    comments: {
      title: "Qué dicen nuestros estudiantes sobre la escuela",
      items: [
        {
          name: "Blendi BR",
          body: `Llevo estudiando 2 meses, ¡y es genial! ¡personal muy amable! Los
            profesores saben lo que hacen y siempre están disponibles para
            ayudarte a aprender. La asesora, Carla me ayudó mucho respondiendo
            todas mis preguntas y acompañándome durante el proceso. ¡¡Lo
            recomiendo mucho!!`,
        },
        {
          name: "Hannah VDS",
          body: `¡Sigo las clases nocturnas desde noviembre y puedo recomendar
              NLCollege con confianza! Los profesores están muy bien informados,
              la configuración del curso está bien pensada y la comunicación y
              los servicios son excelentes. Como anteriormente había tomado
              clases en mi país de origen, se me ofreció una prueba de nivel
              para establecer en qué nivel inscribirme.`,
        },
        {
          name: "Jan E",
          body: `Si vas en serio con lo de aprender un idioma, no busques más.
              Intenté estudiar en otra academia y con Babel, pero nada funciona
              como NLCollege. Y el servicio definitivamente supera el precio.`,
        },
      ],
    },
    video: {
      title: "Conoce a tu profesor, Garreth",
      url: "https://www.youtube.com/embed/vv3is_noaHA",
      button: "QUIERO APRENDER INGLES",
    },
  },
  thanks: {
    title: "GRACIAS!",
    subtitle:
      "¡Estás un paso más cerca de empezar a aprender inglés de una vez por todas!",
    text: "Uno de nuestros agentes se pondrá en contacto contigo pronto.",
  },
};
