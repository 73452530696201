export default {
  menus: {
    campus: "Campus",
    courses: "Courses",
    contact_us: "Contact Us",
    student_life: "Student Life",
    accommodation: "Accommodation",
    exams: "Exams",
    visa: "Visa",
    extra_activities: "Extra Activities",
    health_insurance: "Health Insurance",
    spanish_courses: "Spanish Courses",
    english_courses: "English Courses",
    placement_test: "Placement Test",
    price_list: "Price List",
  },
  home: {
    header: {
      title: "Live, learn and speak!",
      subtitle: `Learn English or Spanish with our native teachers and experience the best part of
          <b class="text-secondary">Barcelona</b> and
          <b class="text-tertiary">Madrid</b>`,
    },
    welcome_spain: {
      title: "¡BIENVENIDO A ESPAÑA!",
      subtitle:
        "It's time to learn a new language and to have another perspective of life!",
      features: {
        1: {
          title: "Rich culture",
          desc: "From great artists such as Picasso, Gaudí & Salvador Dalí to the traditional festivals and a lot of historical wealth: in Spain you will breathe culture!",
        },
        2: {
          title: "Spanish people",
          desc: "Spanish people are really welcoming and friendly! You can expect people to be very receptive and passionate about their country. Used to living with foreigners, you will feel the welcome of the Spanish people.",
        },
        3: {
          title: "Gastronomy",
          desc: "If you are a food lover, you are in the right place! From the famous tapas to the traditional paellas, get ready to taste foods you will never forget!",
        },
        4: {
          title: "Gorgeous beaches",
          desc: "With an average of 300 days of sunshine a year, the weather in Spain is perfect for relaxing on the beach. From Ibiza to Mallorca, whatever your style, you're sure to be enchanted by the paradise beaches of Spain!",
        },
      },
      button: "discover about our courses",
    },
    welcome_nlc: {
      title: "Bienvenidos a NL College",
      subtitle:
        "Our school was founded in 2017 and our main focus is that you learn Spanish, but also create memories and live unforgettable experiences. Get ready for personal and professional development in addition to making lifelong friendships!",
      features: {
        1: "Unique Structure",
        2: "Schedule flexibility",
        3: "Extra Activities",
        4: "Extra curricular classes",
      },
      button: "I WANT TO BE A STUDENT AT NL COLLEGE",
    },
    certifications: "Certifications",
    campuses: {
      barcelona: {
        title: "Barcelona",
        desc: `<p>
            The best way to learn Spanish is living in Spain, and you can
            do it by living in one of the most popular destinations in the
            world, in Barcelona. It is like living a dream in a city full
            of culture, good food and an incredible atmosphere. With a
            lifestyle that mixes a big city with magnificent beaches, for
            music, art and sport lovers, the student will have an
            unforgettable life experience.
          </p>
          <p>
            <b>
              Get ready to live in a vibrant, cheerful and full of life
              city!
            </b>
          </p>`,
        button: "I WANT TO STUDY IN BARCELONA",
      },
      madrid: {
        title: "Madrid",
        desc: `<p>
            Living in Madrid is simply living in one of the most charming
            and well-known cities in the world. One of the highest quality
            of urban life in Europe, get ready to study Spanish while
            experiencing the best of a big city, always with the high
            quality standard of teaching.
          </p>
          <p>
            <b> Get ready to live in the heart of Spain! </b>
          </p>`,
        button: "I WANT TO STUDY IN MADRID",
      },
    },
    courses: {
      title: "About our courses",
      subtitle:
        "At NL College we offer the Spanish course that will perfectly fit into your lifestyle. Choose the course you most identify with and get ready to start your immersion in Spanish culture!",
      features: {
        1: {
          title: "Intensive",
          subtitle:
            "The most popular and renowned courses in our school for learning at a fast pace.The courses are designed for adults who want to improve their communication, writing, and grammatical skills.",
          button: "I WANT TO KNOW MORE",
        },
        2: {
          title: "Semi-Intensive",
          subtitle:
            "This course is geared toward those that have restricted time to invest in Spanish studies or those that wish to mix it with their work or different studies.",
          button: "I WANT TO KNOW MORE",
        },
        3: {
          title: "Evening classes",
          subtitle:
            "Our evening Spanish course is perfect for people of all ages with a day job, university studies, or other responsibilities throughout the day.",
          button: "I WANT TO KNOW MORE",
        },
      },
    },
    support: {
      title: "Support in your native language",
      subtitle:
        "We know that moving to another country to learn a new language is a challenge and we want your process in choosing a Spanish course to be as clear as possible. Therefore, our team has highly qualified professionals ready to help you and answer your questions in your own native language.",
      button: "TALK TO OUR AGENTS",
    },
    student_life: {
      title: "Student Life",
      subtitle:
        "Studying at NL College is learning Spanish with excellence and still experiencing Spain to the fullest. From Barcelona, take your time to make new friends and collect memories for a lifetime!",
      features: {
        1: {
          title: "Accommodation",
          button: "I WANT TO KNOW MORE",
        },
        2: {
          title: "Visa",
          button: "I WANT TO KNOW MORE",
        },
        3: {
          title: "Health Insurance",
          button: "I WANT TO KNOW MORE",
        },
        4: {
          title: "Exams",
          button: "I WANT TO KNOW MORE",
        },
      },
    },
  },
  barcelona: {
    header: {
      title: "Barcelona",
    },
    campus: {
      title: "Our Campus",
      desc: `<p class="text-medium">
          With a modern building and a dynamic and immersive atmosphere in
          Spanish culture, take advantage of everything that NL College has
          to offer to develop your Spanish with excellence.
        </p>

        <p class="text-medium">
          <b>
            In addition to high quality teaching, we also value your
            experience inside and outside the classroom.
          </b>
          Our classrooms respect the maximum limit of 12 students per class,
          we offer Spanish courses for all your needs with highly qualified
          teachers and native Spanish speakers.
        </p>`,
      button: "I WANT TO BE A STUDENT",
      features: {
        1: "<b>5 floors</b> of classroom",
        2: "<b>New and modern</b> <br> installations",
        3: "Student <b>lounge</b>",
      },
    },
    why_barcelona: {
      title: "Why Choose Barcelona",
      desc: `
        <p>
          Barcelona is the second largest city in Spain, the third most
          visited in Europe and it has a lot to offer.
        </p>
        <p>
          Learning Spanish in Barcelona gives you the opportunity to
          discover <b>a city full of colours and an incredible nightlife!</b> In
          addition, you will be surrounded by culture, sports, good food,
          history and art! From Gaudí to the Gothic Quarter, you will be
          delighted by every corner of Barcelona.
        </p>
        <p>
          We encourage our students to practice Spanish every day, every
          time! Whether participating in our extra daily activities or
          everyday life! Live Barcelona to the fullest!
        </p>
        <p class="text-dark"><b>Squeeze the city!</b></p>
        `,
      button: "I WANT TO STUDY IN BARCELONA",
    },
    team: {
      title: "Team",
      subtitle:
        "Our team is ready to help you before and after arriving in Barcelona answering all your questions in your own native language.",
      people: {
        1: {
          name: "Andre",
          nationality: "Brazilian",
        },
        3: {
          name: "Carla",
          nationality: "USA",
        },
        4: {
          name: "Kun",
          nationality: "Chinese",
        },
      },
    },
    methodology: {
      title: "Methodology",
      subtitle:
        "The main objective of our NL College courses is communication.",
      desc: `<p>
          <strong>
            The goal of the grammar, pronunciation and vocabulary that you learn with us, 
            is to be able to communicate in Spanish in the most effective way.
          </strong>
        </p>`,
      items: {
        1: `NL College offers Spanish courses at the different levels achieved by the Common European Framework of Reference <a target="_blank" href="https://www.coe.int/en/web/common-european-framework-reference%20languages/level-descriptions">CEFR</a> and described for Spanish in the Instituto Cervantes <a target="_blank" href="https://cvc.cervantes.es/ensenanza/biblioteca_ele/plan_curricular/default.htm">PCIC</a> Curriculum Plan.`,
        2: "You will talk Spanish from the very first day in class. NL College’s teaching approach is based on the current philosophy of linguistic immersion: learning and teaching entirely in Spanish. All the textbooks and teaching materials are in Spanish from the very beginning.",
        3: "At NL College we have textbooks made for teaching Spanish to foreigners, especially selected because of their modern and complete methodology. They are the “Nuevo Prisma” series from the Edinumen editorial from A1 to C2. The book set includes a student textbook, a student workbook as well as a digital extension in the ELEteca with content and activities that expand the material as complementary exercises and hand-outs and audio visual materials.",
        4: "The NL College Spanish courses are made following the communicative approach, action-oriented and student-centred. Clear and accessible grammatical reflection with activities for the student to actively participate in its acquisition.",
        5: "NL College provides learning and communication strategies for students to reflect on their learning process. Intercultural reflection and approach to the cultural diversity of the Hispanic world.",
        6: "At NL College you will develop the different activities of the language that includes comprehension, expression, interaction or mediation following the CEFR.",
        7: "For NL College the quality of teaching is of utmost importance. Our teacher team consists solely of highly qualified native Spanish speaking teachers with experience teaching Spanish to foreigners. All our NL College teachers are higher-education studies in Spanish language and with specific training in ELE (teaching Spanish as a Foreign Language).",
      },
      button: "I WANT TO STUDY IN BARCELONA",
    },
    levels: {
      button: "I WANT TO STUDY IN BARCELONA",
    },
  },
  madrid: {
    header: {
      title: "Madrid",
    },
    campus: {
      title: "Our Campus",
      desc: `<p class="text-medium">
          <b>You will study in the heart of Madrid.</b> Everything you need
          is around you: public transport, beautiful landmarks, all kinds of
          food and loads of people from all over the world!
        </p>
        <p class="text-medium">
          Madrid is the third largest city in the European Union, after
          London and Berlin. Learn Spanish through arts, sports and a
          welcoming atmosphere, and embrace the diversity of what Madrid has
          to offer. It is a jungle ready to be explored.
        </p>`,
      button: "I WANT TO BE A STUDENT",
      features: {
        1: "Located in the <b>heart of the city</b>",
        2: "<b>Native Spanish</b> speakers teachers",
        3: "Student <b>lounge</b>",
      },
    },
    why_madrid: {
      title: "Why Choose Madrid",
      desc: `<p>
              Madrid is the capital of Spain.
              <b class="text-dark">
                It's the capital where everything happens.
              </b>
              You can explore the city using the subway, or take advantage of
              Madrid International Airport that will take you to 154 different
              destinations.
            </p>
            <p>
              Visit El Retiro, the most important Park of Madrid to see the
              green side of the city. Go to one of the largest fish markets in
              the world, or take a selfie at Plaza Mayor and learn about the
              city’s culture. Madrid also celebrates the 2nd largest Gay Pride
              parade in the world. Our school wants to make sure you have the
              best time in Spain, whether you’re trying to improve your skills
              or live a new experience, your journey starts here!
            </p>`,
      button: "I WANT TO STUDY IN MADRID",
    },
    team: {
      title: "Team",
      subtitle:
        "Our team is ready to help you before and after arriving in Madrid answering all your questions in your own native language.",
      people: {
        1: {
          name: "Angélica",
          nationality: "Russian",
        },
        2: {
          name: "Hirane",
          nationality: "Brazilian",
        },
        3: {
          name: "Nishant",
          nationality: "Indian",
        },
        4: {
          name: "Mohamed",
          nationality: "Moroccan",
        },
        5: {
          name: "Sonia",
          nationality: "Chinese",
        },
      },
    },
    methodology: {
      title: "Methodology",
      subtitle:
        "The main objective of our NL College courses is communication.",
      desc: `<p>
          <strong>
            The goal of the grammar, pronunciation and vocabulary that you learn with us, 
            is to be able to communicate in Spanish in the most effective way.
          </strong>
        </p>`,
      items: {
        1: `NL College offers Spanish courses at the different levels achieved by the Common European Framework of Reference <a target="_blank" href="https://www.coe.int/en/web/common-european-framework-reference%20languages/level-descriptions">CEFR</a> and described for Spanish in the Instituto Cervantes <a target="_blank" href="https://cvc.cervantes.es/ensenanza/biblioteca_ele/plan_curricular/default.htm">PCIC</a> Curriculum Plan.`,
        2: "You will talk Spanish from the very first day in class. NL College’s teaching approach is based on the current philosophy of linguistic immersion: learning and teaching entirely in Spanish. All the textbooks and teaching materials are in Spanish from the very beginning.",
        3: "At NL College we have textbooks made for teaching Spanish to foreigners, especially selected because of their modern and complete methodology. They are the “Nuevo Prisma” series from the Edinumen editorial from A1 to C2. The book set includes a student textbook, a student workbook as well as a digital extension in the ELEteca with content and activities that expand the material as complementary exercises and hand-outs and audio visual materials.",
        4: "The NL College Spanish courses are made following the communicative approach, action-oriented and student-centred. Clear and accessible grammatical reflection with activities for the student to actively participate in its acquisition.",
        5: "NL College provides learning and communication strategies for students to reflect on their learning process. Intercultural reflection and approach to the cultural diversity of the Hispanic world.",
        6: "At NL College you will develop the different activities of the language that includes comprehension, expression, interaction or mediation following the CEFR.",
        7: "For NL College the quality of teaching is of utmost importance. Our teacher team consists solely of highly qualified native Spanish speaking teachers with experience teaching Spanish to foreigners. All our NL College teachers are higher-education studies in Spanish language and with specific training in ELE (teaching Spanish as a Foreign Language).",
      },
      button: "I WANT TO STUDY IN MADRID",
    },
    levels: {
      button: "I WANT TO STUDY IN MADRID",
    },
  },
  levels: {
    title: "Levels",
    items: [
      {
        color: "primary",
        name: "C2",
        title: "Mastery",
        hours: "560",
        weeks: 28,
        courses: "C2.1, C2.2, C2.3, C2.4, C2.5, C2.6, C2.7 - ADVANCED",
        diploma: "DELE C2 Diploma",
        details:
          "You will be able to understand, summarise and express yourself very fluently and precisely and can easily understand everything you read or hear.",
      },
      {
        color: "primary",
        name: "C1",
        title: "Domain",
        hours: "480",
        weeks: 24,
        courses: "C1.1, C1.2, C1.3, C1.4, C1.5 and C1.6 - ADVANCED",
        diploma: "DELE C1 Diploma",
        details:
          "You will have the ability to understand longer texts, complex conversations and can express yourself without using obvious words/expressions.",
      },
      {
        color: "tertiary",
        name: "B2",
        title: "Advanced",
        hours: "400",
        weeks: 20,
        courses: "B2.1, B2.2, B2.3, B2.4 and B2.5 - INTERMEDIATE",
        diploma: "DELE B2 Diploma",
        details:
          "You will be able to interact with native speakers with more confidence with a considerable fluency and spontaneity and the ability to easily develop detailed texts with different subjects.",
      },
      {
        color: "tertiary",
        name: "B1",
        title: "Threshold",
        hours: "120",
        weeks: 6,
        courses: "B1.1, B1.2, B1.3 and B1.4 - INTERMEDIATE",
        diploma: "DELE B1 Diploma",
        details:
          "You will be able to travel by yourself without language issues. You will be able to also describe events and dreams and to develop reasonable explanations, opinions and plans.",
      },
      {
        color: "secondary",
        name: "A2",
        title: "Platform",
        hours: "80",
        weeks: 4,
        courses: "A2.1, A2.2 and A2.3 - ELEMENTARY",
        diploma: "DELE A2 Diploma",
        details:
          "You will have the ability to understand the most common expressions and sentences in the major interest areas (as personal information, describe family, and local geography).",
      },
      {
        color: "secondary",
        name: "A1",
        title: "Access",
        hours: "80",
        weeks: 4,
        courses: "A1.1 and A1.2 - BEGINNER",
        diploma: "DELE A1 Diploma",
        details:
          "You will be able to use daily expressions, answer personal detailed questions and have the ability to introduce herself/himself",
      },
    ],
    subtitle:
      "We have four main programs: accelerated spanish course, intensive, semi-intensive, extensive and private lessons for you to choose the one that most fits on your objectives and lifestyle. Every two weeks we have a new A1 class starting, and every Monday you can join NL College, also at the end of your course you will receive a certificate.",
    hours: "hours",
    weeks: "weeks",
    courses: "Courses",
    diploma: "Official DELE Diploma:",
  },
  contactus: {
    title: "Contact Us",
    subtitle: `At NL College we give you the best opportunities to learn English or Spanish and
        maximize your potential to continue learning and build your own
        career. Contact us and get ready to develop your personal skills to
        travel the world with confidence!`,
    campus: {
      title: "Our Campus",
    },
    follow_us: "Follow us on",
  },
  contact_form: {
    title: "Contact Us",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone: "Telephone number / Whatsapp",
    country: "Country",
    nationality: "Nationality",
    campus: "Preferred Campus",
    message: "Message",
    terms:
      "I have read and accept the NL College Privacy Policy, Terms & Conditions, and Cancellation Policy.",
    button: "SEND",
    success_msg: "Thanks, your message has been delivered",
    check_terms_error: "Must accept Terms & Conditions to send the message",
  },
  accommodation: {
    title: "Student Life",
    subtitle: "Accommodation",
    desc: `We know how good it is to feel that someone got your back - especially
        when you are moving to a foreign country - and that is the reason why
        we also provide a service for accommodation in order to facilitate
        your administrative procedure. You will be accommodated in a safe
        place and closer as much possible to the school to maximise your
        comfort thanks to our partners network.`,
    features: {
      title: "Choose the solution that suits you the most among:",
      items: [
        {
          color: "secondary",
          title: "Student Houses",
          img: "/imgs/student_life/accommodation/01.png",
          details: `Student houses are perfect for those who want to live surrounded 
            by other local and international students. They offer great facilities like 
            24h surveillance or cleaning services with all utilities included, which will 
            make your daily life easier. Students have access to their room and they share 
            areas like study rooms, chill-out spaces or even gyms. In these areas, you will 
            get to meet other cultures and enlarge your social circles. `,
        },
        {
          color: "tertiary",
          title: "Shared apartment",
          img: "/imgs/student_life/accommodation/02.png",
          details: `Sharing an apartment with other students is a good compromise if you
              need more independence. This option will give you the chance to
              organise yourself as you like while sharing communal spaces (kitchen,
              dining room, living room…) and benefit from your own bedroom. The
              other good point is that having roommates will give you the
              opportunity to develop your social circle in Barcelona — you all will
              be in the same boat! `,
        },
        {
          color: "primary",
          title: "Hostel",
          img: "/imgs/student_life/accommodation/03.png",
          details: `An option for those who want to have more interaction with the
              international community that lives in Barcelona. In addition to all
              the facilities that a hostel offers, the ease of meeting new people
              and making friends is an added attraction.`,
        },
      ],
      button: "I WANT TO SPEAK WITH AN AGENT",
    },
  },
  visa: {
    title: "Student Life",
    subtitle: "Visa",
    desc: `NL College is ready to assist you throughout the process. Our
        multilingual team is ready to guide you in this very important step of
        your student exchange. We are available to answer any questions and
        the school will provide all the necessary documentation.`,
    features: {
      title: `There are three student visa options depending on the duration of your
          Spanish course. Are they:`,
      items: [
        {
          color: "primary",
          img: "/imgs/visa-02.png",
          title: "Tourist Visa up to",
          days: "90 days",
          category: "C - CATEGORY",
        },
        {
          color: "secondary",
          img: "/imgs/visa-03.png",
          title: "Student Visa up to",
          days: "180 days",
          category: "D - CATEGORY",
        },
        {
          color: "tertiary",
          img: "/imgs/visa-04.png",
          title: "Student Visa beyond",
          days: "180 days up to 1 year",
          category: "D - CATEGORY",
        },
      ],
      subtitle: `You can apply for your visa at the Spanish consulate in your
          country of residence, and the documentation requirements may
          change by territory. If you choose to apply for your visa in
          Spain, you will need to enter as a tourist and then apply for the
          student permit. The general basic documentation typically required
          are:`,
      requirements: `
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>Passport valid for the entire period of the intended stay</p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Valid reason for stay (proof of accommodations, proof of a trip
              itinerary, and return ticket)
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Proof of sufficient economic means for the entire period of the
              intended stay
            </p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>Proof of enrollment in classes (provided by NL College)</p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>Proof of accommodation</p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>Proof of full course payment</p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>Confirmation of school registration</p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>Medical insurance - full coverage</p>
          </li>
          <li class="d-flex align-items-center mb-3 text-medium">
            <i class="text-dark fa-solid fa-check mr-4"></i>
            <p>
              Confirmation of accommodation (rental contract or host invitation)
            </p>
          </li>
        `,
      desc: `
          <p class="mb-5">
            <b class="lh-4">
              If you prefer an even more personalised support, the Legal
              Department of NLC offers you a new and exclusive service that
              you will not find in any other Spanish language school. Our team
              of lawyers will study your case personally and accompany you
              throughout the process. That way, you are guaranteed the highest
              probabilities.
            </b>
          </p>
          <p class="lh-4">
            We offer you the possibility to manage and process your student
            visa without you having to worry about anything. You only have to
            gather the documentation that our lawyers specialised in
            foreigners and visa processing requests. We manage and process the
            visa for you.
          </p>
        `,
      button: "I WANT TO SPEAK WITH AN AGENT",
    },
  },
  healthinsurance: {
    title: "Student Life",
    subtitle: "Health Insurance",
    desc: `If your student visa is long-term, you must have full medical
        coverage. This also applies to students who plan to stay longer than
        90 days.`,
    features: {
      title: `At NL College we are concerned about the safety of our students
          and that is why we want to provide the best coverage and maximum
          peace of mind during their experience in Spain.`,
      desc: `<p class="mb-4 text-medium lh-5">
            Our institution is in charge of providing everything necessary so
            that the student does not have to worry about anything. If the
            reason is for a visa management or because you simply want to have
            the peace of mind of being medically covered while living the
            linguistic immersion, NL College takes care of all the
            administrative and bureaucratic procedures, with the aim and
            commitment to ensure the highest quality.
          </p>
          <p class="text-medium lh-5">
            We collaborate with some of the largest insurance companies with
            the sole objective of providing the student with the highest
            guarantees.
          </p>`,
      subtitle: "MEDICAL INSURANCE PRICES",
      table: `<table class="header-primary body-medium">
            <thead>
              <tr>
                <th>Months</th>
                <th>€</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>3 months</td>
                <td>235.00€</td>
              </tr>
              <tr>
                <td>6 months</td>
                <td>370.00€</td>
              </tr>
              <tr>
                <td>8 months</td>
                <td>460.00€</td>
              </tr>
              <tr>
                <td>12 months</td>
                <td>650.00€</td>
              </tr>
            </tbody>
          </table>`,
      button: "I WANT TO SPEAK WITH AN AGENT",
    },
  },
  exams: {
    title: "Student Life",
    subtitle: "Exams",
    desc: `We celebrate that NL College is an official examination center for the
          DELE and CCSE exams. Our mission is to keep improving to offer a
          better service every day, if possible.`,
    features: {
      title: `Both tests recognize at an international level, the knowledge of the
          Spanish language, through different tests: reading comprehension,
          listening comprehension, written expression and interaction, and oral
          expression and interaction.`,
      items: [
        {
          logo: "/imgs/logo-dele.png",
          desc: `<p class="mb-0">The recognition of DELE diplomas as a 
              certification of linguistic
              competence in Spanish is international, in both in public and
              private educational institutions and authorities, as well as in
              the business world and among chambers of commerce. DELE diplomas
              are diplomas awarded by the Instituto Cervantes, on behalf of the
              Ministry of Education, Culture and Sport of Spain; they have no
              expiration date. They offer exams from level A1 to C2 of the CEFR
              and you must decide in advance which one to take.</p>`,
        },
        {
          logo: "/imgs/logo-siele.png",
          desc: `<p>
                SIELE is the International Spanish Language Evaluation Service
                that certifies the degree of Spanish language proficiency
                through electronic means for students and professionals from all
                five continents. It is an internationally recognized official
                Spanish language exam.
              </p>
              <p class="mb-0">
                It is promoted by the Cervantes Institute, the National
                Autonomous University of Mexico, the University of Salamanca and
                the University of Buenos Aires, which guarantees quality
                standards and good practices in the elaboration of the tests,
                and the use of different linguistic varieties of the Hispanic
                world.
              </p>`,
        },
      ],
      button: "I WANT TO SPEAK WITH AN AGENT",
    },
  },
  extraactivities: {
    title: "Student Life",
    subtitle: "Extra Activities",
    desc: `As part of our curriculum of activities, we offer daily activities and
          extra classes to our students in order to encourage them to experience
          Spanish culture, so rich and with so much to offer. Taking advantage
          of the fact that both campuses are located in world-renowned cities,
          we provide specific activities for students to immerse themselves in
          the local culture and also excursions to expand their experience
          during their period of study.`,
    monthly: {
      title: "Activities and monthly excursions",
      subtitle: `The
          <strong class="text-secondary">
            school organises excursions around the year
          </strong>
          so that the student can discover about the traditional Spanish fiestas
          and with them, they can immerse themselves in the Spanish culture.
          From a magnificent flamenco class, to an unforgettable paella tasting
          or even for an after class tapas, we are determined to provide our
          students with the best experience possible.`,
    },
    quarterly: {
      title: "Quarterly activities and excursions",
      subtitle: `
          <b class="text-tertiary">
          Each trimester there will be a special excursion
          </b>, which may be outside of Spain, coinciding with the end of the
          course stipulated in the academic program. The goal is for you
          to share a trip with your classmates so that together you can
          live an unforgettable experience.
          `,
    },
    extra: {
      title: "Extra classes",
      items: [
        {
          img: "/imgs/extra-activities-05.png",
          title: "Job Interview",
          desc: `We know how challenging finding a job in a foreign country can
              be and we want to provide all the tools for our students to
              achieve their goals. With weekly job interview preparation
              classes, the student will develop conversational skills to feel
              confident enough during this process.`,
        },
        {
          img: "/imgs/extra-activities-06.png",
          title: "Catalan Workshop",
          desc: `Catalans have a lot of respect for their roots, in order to
                immerse our students in the local culture, we offer a Catalan
                workshop to help the student develop their language skills while
                interacting with each other.`,
        },
        {
          img: "/imgs/extra-activities-07.png",
          title: "Barcelona/Madrid Culture & History",
          desc: `A specific class for each campus in order to bring the student
                closer to the city where he/she is living. We want our students
                to know the landmarks that cities offer, at the same time that
                they understand them as a local does. The student will practice
                reading, speaking, listening and writing in a dynamic and
                relaxed atmosphere that will add great cultural value to their
                experience.`,
        },
        {
          img: "/imgs/extra-activities-08.png",
          title: "Book Club",
          desc: `This activity aims to read, discuss and learn from a book. So
                that there is a promotion of reading and the development of
                constructive criticism that encourages debates to the ideas of
                others. Conversation, comprehension, reading and best of all,
                sharing time with classmates to have a great time together.`,
        },
      ],
      button: "I WANT TO BE A STUDENT",
    },
  },
  spanishcourses: {
    title: "Spanish Courses",
    sections: [
      {
        title: "Spanish courses",
        children: [
          {
            title: "Accelerated spanish course",
            description: `
              <p>
                The most popular and famous courses in our Spanish school for learning at a fast pace, specially designed for long-term students, meets all the requirements to get your visa.
              </p>
              <p>
                The courses are for students who want to improve their communication, comprehension, writing, and grammatical skills in a short period of time.
              </p>
              <p>
                The intensive course consists of <i>4 hours</i> per day, <i>which means, 20 hours per week and two weeks per course.</i> There is the possibility of joining our intensive courses every Monday of the week throughout the year.
              </p>
              `,
            details: `<ul>
                <li><b>20 Lesson:</b> per week.</li>
                <li><b>4 lessons daily:</b> Monday to Friday.</li>
                <li><b>Class duration:</b> Each lesson is 55 minutes</li>
                <li>
                  <b>Timetable:</b> <br />
                  <ul class="disc-style">
                    <li>9.30am to 1.30pm - Monday to Friday.*</li>
                    <li>2pm to 6pm - Monday to Friday.*</li>
                  </ul>
                </li>
                <li><b>Start dates:</b> Every Monday.</li>
                <li><b>Levels:</b> A1, A2, B1, B2, C1, C2.</li>
                <li>One level in four weeks**</li>
              </ul>`,
            price_details: `
                <div class="bg-white b-radius-3 p-4 text-center mt-4">

                  <p>Contact us and get a better price, because more weeks are equal to more discounts.</p>

                  <p>
                    For your long term course of 27 weeks or more, we have a special deal  for you. <br>
                    Contact us for further information.
                  </p>
                </div>
              `,

            small_details:
              "*according to the availability / **ask for more information",
            button: "I WANT TO KNOW",
          },
          {
            title: "Semi-Intensive spanish course",
            description: `
              <p>
                This course is geared toward those <i>students</i> that have restricted time to invest in Spanish learning or those that wish to <i>combine</i> it with their work or different studies. We provide a pretty cluster dynamically and are more economical than individual classes. 
              </p>
              <p>
                This course consists of 10 hours per week, <i>which means,</i> 2 hours per day, which is able to permit you to create constant progress in learning the language.
              </p>
              `,
            details: `<ul>
                <li><b>10 Lesson:</b> per week.</li>
                <li><b>2 lessons daily:</b> Monday to Friday</li>
                <li><b>Class duration:</b> Each lesson is 55 minutes</li>
                <li>
                  <b>Timetable:</b> <br />
                  <ul class="disc-style">
                    <li>2pm to 4pm - Monday to Friday.*</li>
                    <li>5pm to 7pm - Monday to Friday.*</li>
                  </ul>
                </li>
                <li><b>Start dates:</b> Every Monday.</li>
                <li><b>Levels:</b> A1, A2, B1, B2, C1, C2.</li>
                <li>One level in 8 weeks**</li>
              </ul>`,
            price_details: `
                <div class="bg-white b-radius-3 p-4 text-center mt-4">

                  <p>Contact us and get a better price, because more weeks are equal to more discounts.</p>
                </div>
              `,

            small_details:
              "*according to the availability / **ask for more information",
            button: "I WANT TO KNOW",
          },
          {
            title: "Evening Clases",
            description: `
              <p>
                Our evening Spanish course is perfect for students with daily jobs, university studies, or other responsibilities throughout the week. 
              </p>
              <p>
                The Evening Spanish course takes place twice a week, 2 hours per day, either Monday and Wednesday or Tuesday and Thursday from 18:30 to 20:30 giving you plenty of time to work, study, or enjoy the city during the day while still making consistent language-learning progress
              </p>
              `,
            details: `<ul>
                <li><b>4 Lessons:</b> per week.</li>
                <li><b>2 lessons daily:</b> Monday and Wednesday or Tuesday and Thursday.</li>
                <li><b>Class duration:</b> Each lesson is 55 minutes</li>
                <li>
                  <b>Timetable:</b> <br />
                  <ul class="disc-style">
                    <li>6.30pm to 8.30pm</li>
                  </ul>
                </li>
                <li><b>Start dates:</b> Every week.</li>
                <li><b>Levels:</b> A1, A2, B1, B2, C1, C2.</li>
                <li>One level in 10 weeks*</li>
              </ul>`,
            price_details: `
                <div class="bg-white b-radius-3 p-4 text-center mt-4">

                  <p>Contact us and get a better price, because more weeks are equal to more discounts.</p>
                </div>
              `,

            small_details: "*ask for more information",
            button: "I WANT TO KNOW",
          },
        ],
      },
      {
        title: "Private Classes",
        children: [
          {
            title: "One-to-one / Two-to-one / Three-to-one",
            description: `
              <p>
                Flexible and according to your needs, the teacher will adapt to your learning style and specific Spanish knowledge goals. You will get the schedule that you want, it doesn't matter if you are available in the morning or in the evening, any time and any day, just let us know and we will do it for you. 
              </p>
              <p>
                It can be for a person, or small group of up to 3 people, our classes are made up of native Spanish teachers with experience teaching Spanish to foreigners. <br>
                The classes will be  in person at the school or we also offer the option to go wherever you are, either at your home or workplace.
              </p>
              <p>
                Also you can choose virtual private classes, which have the same price as the in person private lessons, but using the video conferencing platform called zoom.
              </p>
              `,
            details: null,
            price_details: null,
            small_details: null,
            button: "I WANT TO KNOW",
          },
        ],
      },
    ],
    button: "I WANT TO BE A STUDENT",
  },
  englishcourses: {
    title: "English Courses",
    desc: `<p>If you want to continue developing your professional and personal life, choose our English courses. With flexible schedules always adapted to your needs and dynamic classes with native teachers.</p> <br> <p><b>Prepare for the next step in your life.</b></p>`,
    courses: {
      semi_intensive: {
        title: "Semi-intensive English course",
        details: `<ul> <li><b>10 hours a week:</b> Monday to Friday</li> <li><b>2 hours daily</b></li> <li><b>Class duration:</b> 55 minutes per lesson.</li> <li> <b>Schedule:</b> <br /> <ul class="disc-style"> <li>9.30am to 11.30am - Monday to Friday.</li> </ul> </li> <li><b>Start of classes:</b> Every week.</li> <li><b>Levels:</b> A1, A2, B1, B2, C1, C2.</li> <li>1 level in 8 weeks**</li> </ul>`,
        button: "I WANT TO KNOW MORE",
      },
      afternoon: {
        title: "Afternoon English course",
        details: `<ul> <li><b>4 hours a week:</b> Mon-Wed or Tue-Thu</li> <li><b>2 hours daily</b></li> <li><b>Class duration:</b> 55 minutes per lesson.</li> <li> <b>Schedule:</b> <br /> <ul class="disc-style"> <li>6.30pm to 8.30pm</li> </ul> </li> <li><b>Start of classes:</b> Every week.</li> <li><b>Levels:</b> A1, A2, B1, B2, C1, C2.</li> <li>1 level in 10 weeks**</li> </ul>`,
        button: "I WANT TO KNOW MORE",
      },
      private: {
        title: "Private classes",
        details: `<ul> <li>Flexible and adapted to your needs, classes can be in person at our school, online using the ZOOM platform or we can move to where you are, whether it is in your own home, a cafe or your workplace.</li> </ul>`,
        button: "I WANT TO KNOW MORE",
      },
    },
    button: "I WANT TO BE A STUDENT",
  },
  privacypolicy: {
    title: "Política de privacidad",
    content: `<h4 class="mb-4 text-primary">POLÍTICA DE PRIVACIDAD REDES SOCIALES</h4>
        <p>
          En cumplimiento de la normativa vigente en Protección de Datos de
          Carácter Personal (RGPD) y la Ley 34/2002, de 11 de julio, de
          Servicios de la Sociedad de la Información y de Comercio Electrónico
          (LSSI-CE), NATIVE LANGUAGE COLLEGE informa a los usuarios, que ha
          procedido a crear un perfil en las Redes Sociales Facebook, Twitter,
          Instagram, Linkedin y Google +, con la finalidad principal de
          publicitar sus productos y servicios.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Datos de NATIVE LANGUAGE COLLEGE:</h4>
        <ul class="mb-4 disc-style">
          <li>CIF: B87792370</li>
          <li>DIRECCIÓN: PLAZA PUERTA DEL SOL, 11 2ª 28013, MADRID</li>
          <li>CORREO ELECTRÓNICO: info@nlcollege.es</li>
          <li>DOMINIO WEB: www.nlcollege.es</li>
        </ul>
        <p>
          El usuario dispone de un perfil en la misma Red Social y ha decidido
          unirse a la página creada por NATIVE LANGUAGE COLLEGE, mostrando así
          interés en la información que se publicite en la Red. Al unirse a
          nuestra página, nos facilita su consentimiento para el tratamiento de
          aquellos datos personales publicados en su perfil.
        </p>
        <p>
          El usuario puede acceder en todo momento a las políticas de privacidad
          de la propia Red Social, así como configurar su perfil para garantizar
          su privacidad.
        </p>
        <p>
          NATIVE LANGUAGE COLLEGE tiene acceso y trata aquella información
          pública del usuario, en especial, su nombre de contacto. Estos datos,
          sólo son utilizados dentro de la propia Red Social. No son
          incorporados a ningún sistemas de tratamiento.
        </p>
        <p>
          En relación a los derechos de acceso, rectificación, limitación de
          tratamiento, supresión, portabilidad y oposición al tratamiento de sus
          datos de carácter personal, de los que usted dispone y que pueden ser
          ejercitados ante NATIVE LANGUAGE COLLEGE, de acuerdo con la RGPD, debe
          tener en cuenta los siguientes matices:
        </p>
        <ul class="mb-4 disc-style">
          <li>
            Derecho de Acceso: Es el derecho del usuario a obtener información
            sobre sus datos concretos de carácter personal y del tratamiento que
            se haya realizado o realice, así como de la información disponible
            sobre el origen de dichos datos y las comunicaciones realizadas o
            previstas de los mismos.
          </li>
          <li>
            Derecho de Rectificación: Es el derecho del afectado a que se
            modifiquen los datos que resulten ser inexactos o incompletos. Sólo
            podrá satisfacerse en relación a aquella información que se
            encuentre bajo el control de NATIVE LANGUAGE COLLEGE, por ejemplo,
            eliminar comentarios publicados en la propia página, imágenes o
            contenidos web donde consten datos de carácter personal del usuario.
          </li>
          <li>
            Derecho a la Limitación de tratamiento: Es el derecho a que se
            limiten los fines del tratamiento previstos de forma original por el
            responsable del tratamiento.
          </li>
          <li>
            Derecho de Supresión: Es el derecho a suprimir los datos de carácter
            personal del usuario, a excepción de lo previsto en el propio RGPD o
            en otras normativas aplicables que determinen la obligatoriedad de
            la conservación de los mismos, en tiempo y forma.
          </li>
          <li>
            Derecho de portabilidad: El derecho a recibir los datos personales
            que el usuario, haya facilitado, en un formato estructurado, de uso
            común y lectura mecánica, y a transmitirlos a otro responsable.
          </li>
          <li>
            Derecho de Oposición: Es el derecho del usuario a que no se lleve a
            cabo el tratamiento de sus datos de carácter personal o se cese el
            tratamiento de los mismos por parte de NATIVE LANGUAGE COLLEGE.
          </li>
        </ul>
        <h4 class="mb-4 mt-5 text-dark">
          NATIVE LANGUAGE COLLEGE realizará las siguientes actuaciones:
        </h4>
        <ul class="mb-4 disc-style">
          <li>Acceso a la información pública del perfil.</li>
          <li>
            Publicación en el perfil del usuario de toda aquella información ya
            publicada en la página de
          </li>
        </ul>
        <h4 class="mb-4 mt-5 text-dark">NATIVE LANGUAGE COLLEGE.</h4>
        <ul>
          <li>
            Enviar mensajes personales e individuales a través de los canales de
            la Red Social.
          </li>
          <li>
            Actualizaciones del estado de la página que se publicarán en el
            perfil del usuario.
          </li>
        </ul>
        <p>
          El usuario siempre puede controlar sus conexiones, eliminar los
          contenidos que dejen de interesarle y restringir con quién comparte
          sus conexiones, para ello deberá acceder a su configuración de
          privacidad.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Publicaciones</h4>
        <p>
          El usuario, una vez unido a la página de NATIVE LANGUAGE COLLEGE,
          podrá publicar en ésta última comentarios, enlaces, imágenes o
          fotografías o cualquier otro tipo de contenido multimedia soportado
          por la Red Social. El usuario, en todos los casos, debe ser el titular
          de los mismos, gozar de los derechos de autor y de propiedad
          intelectual o contar con el consentimiento de los terceros afectados.
          Se prohíbe expresamente cualquier publicación en la página, ya sean
          textos, gráficos, fotografías, vídeos, etc. que atenten o sean
          susceptibles de atentar contra la moral, la ética, el buen gusto o el
          decoro, y/o que infrinjan, violen o quebranten los derechos de
          propiedad intelectual o industrial, el derecho a la imagen o la Ley.
          En estos casos, NATIVE LANGUAGE COLLEGE se reserva el derecho a
          retirar de inmediato el contenido, pudiendo solicitar el bloqueo
          permanente del usuario.
        </p>
        <p>
          NATIVE LANGUAGE COLLEGE no se hará responsable de los contenidos que
          libremente ha publicado un usuario.
        </p>
        <p>
          El usuario debe tener presente que sus publicaciones serán conocidas
          por los otros usuarios, por lo que él mismo es el principal
          responsable de su privacidad.
        </p>
        <p>
          Las imágenes que puedan publicarse en la página no serán almacenadas
          en ningún sistemas de tratamiento por parte de NATIVE LANGUAGE
          COLLEGE, pero sí que permanecerán en la Red Social.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Concursos y promociones</h4>
        <p>
          NATIVE LANGUAGE COLLEGE se reserva el derecho a realizar concursos y
          promociones, en los que podrá participar el usuario unido a su página.
          Las bases de cada uno de ellos, cuando se utilice para ello la
          plataforma de la Red Social, serán publicadas en la misma. Cumpliendo
          siempre con la LSSI-CE y con cualquier otra norma que le sea de
          aplicación. La Red Social no patrocina, avala ni administra, de modo
          alguno, ninguna de nuestras promociones, ni está asociada a ninguna de
          ellas.
        </p>
        <h4 class="mb-4 mt-5 text-dark">Publicidad</h4>
        <p>
          NATIVE LANGUAGE COLLEGE utilizará la Red Social para publicitar sus
          productos y servicios, en todo caso, si decide tratar sus datos de
          contacto para realizar acciones directas de prospección comercial,
          será siempre, cumpliendo con las exigencias legales de la RGPD y de la
          LSSI-CE.
        </p>
        <p>
          No se considerará publicidad el hecho de recomendar a otros usuarios
          la página de NATIVE LANGUAGE COLLEGE para que también ellos puedan
          disfrutar de las promociones o estar informados de su actividad.
        </p>
        <p>
          A continuación detallamos el enlace a la política de privacidad de la
          Red Social:
        </p>
        <ul class="mb-4 disc-style">
          <li>Facebook: https://www.facebook.com/help/323540651073243/</li>
          <li>Twitter: https://twitter.com/privacy</li>
          <li>Instagram: http://instagram.com/about/legal/privacy/</li>
          <li>
            Linkedin:
            http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv
          </li>
          <li>Google +: http://www.google.com/intl/es/policies/privacy/</li>
        </ul>`,
  },
  price_list: {
    title: "Student Life",
    subtitle: "Price List",
    desc: {
      title: "Course Prices",
      description: `
        Our school is ready to welcome you! We are delighted to offer the most competitive prices with an exceptional balance between quality, unique facilities and Spanish immersion experience.
        <br> <br>
        With options between Accelerated (20 hours per week), Semi-Intensive (10 hours per week) ou Extensive (4 hours per week). Choose the course that better fits your needs,
        <br> <br>
        Choose the course that best fits your needs, the number of weeks and get ready to jump to the next level in your life.`,
      tip: "Tip: for the best value for money, check out our available package options below.",
    },
    table: {
      weeks: "WEEKS",
      week: "week",
      accelerated: "ACCELERATED",
      semi_intensive: "SEMI-INTENSIVE",
      extensive: "EXTENSIVE",
    },
    private: {
      title: "Private Classes",
      description: `
        Flexible and according to your needs, the teacher will adapt to your learning style and specific Spanish knowledge goals. You will get the schedule that you want, it doesn't matter if you are available in the morning or in the evening, any time and any day, just let us know and we will do it for you.
        <br><br>
        It can be for a person, or small group of up to 3 people, where you can split the cost between your friends and save more money.
        <br><br>
        The classes will be in person at the school or we also offer the option to go wherever you are, either at your home or workplace.
        <br><br>
        Also you can choose virtual private classes, which have the same price as the in person private lessons, but using the video conferencing platform.
        `,
      items: {
        1: "Registration fee: € 40 - One time only",
        2: "Siele Exam + Preparation: € 190",
        3: "Work Book € 25 / Grammar Book € 25",
      },
    },
    table2: {
      title: "PRIVATE CLASSES: 90 minutes (Prices are per person and hour)",
      hours_pack: "Hours Packages",
      hours_a: "From 1,5 to 10 hrs",
      hours_b: "From 10 to 20 hrs",
      hours_c: "From 21 to 50 hrs",
      hours_d: "Outside",
      add: "Add",
    },
    special_packages: {
      title: "Special Packages",
      description: `
          If you are looking for a long term visa and peace of mind, here you can find the best options.
          <br><br>
          All our packages includes:`,
      items: {
        1: "Registration fee",
        2: "All books",
        3: "Exclusively student App",
        4: "Siele Preparation Course",
        5: "Exam",
        6: "Cultural Immersive Activities",
        7: "Welcome Kit",
      },
      description_2: "Don't worry with anything besides learning Spanish!",
    },
    long_term: {
      title: "Long Term Acelerado PACKAGE (20h per week)",
      subtitle:
        "Registration fee + All books included + App + Extra activites + Welcome kit + SIELE preparation course + Exam",
      weeks: "Weeks",
      holidays: "Holidays",
      months: "Months",
      price: "Price",
    },
    dele_exam: {
      title: "Dele Preparation Exam",
      description: `
        DELE is organised by the Instituto Cervantes and it's an internationally recognized qualification in Spanish Language competency.
        <br><br>
        This is the most important exam where you are going to show your Spanish level. It opens many doors in your professional life all over the world and also with the admission to many Spanish Universities. Get ready for the exam with our DELE Preparation Course.`,
    },
    dele_table: {
      title: "DELE preparation course (20h per week)",
      subtitle: "Registration fee and book included",
      weeks: "Weeks",
    },
    extras: {
      title: "Extra Services",
      table1: {
        title: "Accommodation",
        items: [
          { label: "Student Residence", value: "From € 650 / month" },
          { label: "Shared Apartment", value: "From € 400 / month" },
          { label: "Hostel", value: "From € 145 / week" },
        ],
      },
      table2: {
        title: "Settling down",
        items: [
          { label: "Airport pick up", value: "From € 65" },
          { label: "Visa application support", value: "€ 400" },
          { label: "Settle down Visa in Spain", value: "€ 110" },
        ],
      },
    },
    button: "I WANT TO BE A STUDENT",
  },
  placement_test: {
    title: "Placement Test",
    desc: `
      <b>The primary function of the Placement Test is to assess a candidate's 
      Spanish language proficiency. </b> <br><br>

      The test will determine overall proficiency level from Level 1 (Beginner) 
      to Level 5 (Advanced). This level test was carefully designed by our 
      Academic team. Once you complete the test our team will enroll you in a 
      level that is suitable for your current language abilities.
    `,
    instructions: {
      desc: `
        At NL College we endeavour to ensure that all our students are placed at the 
        appropriate level. You will only be enrolled in a class that is matched to 
        your current abilities. This is done to ensure that you make good progress 
        in your course.
      `,
      title: "Instructions",
      items: [
        "There are 84 multiple-choice questions",
        "Each question tests a key feature of Spanish structure",
        "Grammatical structures range from simple to complex",
        "Read the questions and tick one of the options. There is only one correct answer to each question",
        "Allow yourself approximately 45 minutes to complete the tes",
        "The questions become more difficult as the test progresses",
        "If the questions become too difficult, skip to the end of the test and click on the 'finish' button",
      ],
    },
    thanks: "Thank you very much for your time and good luck!",
    button: "START THE TEST",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfwuDtDS4d0ceP5wSHPQCOHdW2I9VdJO0VLeX9Uo-GCDqoZlA/viewform",
  },
  learnspanish: {
    contactus: "CONTACT US",
    title: "NOW’S THE TIME TO <br /> LEARN SPANISH",
    subtitle:
      "Don't miss out on more opportunities. <br /> By studying at NL COLLEGE, you'll be able to:",
    head_items: [
      "Become the perfect candidate and lock in the position you desire",
      "Get the salary you deserve",
      "Build a new life in Spain and start living like a local",
      "Choose to live in a wide variety of Spanish-speaking countries",
    ],
    form: {
      title: "Start now!",
      subtitle: "Don't miss this opportunity",
      name: "Full name",
      email: "Email",
      phone: "Phone",
      nationality: "Nationality",
      button: "GO ON!",
      success_msg:
        "Thank you, You're one step closer to start learning Spanish for good!",
    },
    whylearn: {
      title: "Why you should learn Spanish",
      testimonials: [
        {
          img: "/imgs/landing_0323/gustavo.png",
          name: "Gustavo A (Brazil)",
          linkedin: "https://www.linkedin.com/in/gustavoassis/",
          comments: `
            While living in Brazil, I thought that only Portuñol was enough. But I saw
            that I needed more if I wanted to advance in my career. Therefore, I
            chose to study Spanish and I see that besides opening professional
            doors, it gave me the opportunity to meet more people, make friends
            and enjoy my travels more.
          `,
        },
        {
          img: "/imgs/landing_0323/erik.png",
          name: "Erik H (Brazil)",
          instagram: "https://www.instagram.com/erikhnsilva/",
          comments: `
            There is nothing more exciting than learning the Spanish language
            and living day to day like a local and enjoying one of the best
            cities in the world. Living in Barcelona is like never leaving the
            classroom.
          `,
        },
        {
          img: "/imgs/landing_0323/kun.png",
          name: "Kun Y (China)",
          linkedin: "https://www.linkedin.com/in/kunyang19970415/",
          comments: `
            Learning Spanish allowed me to get to know a culture completely
            different from my own. I learned the basics of the language in
            school. In addition to English and Mandarin, I needed Spanish to be
            able to move freely around the world. When the time came to study
            for a postgraduate degree, I did not hesitate to come to Spain to
            continue my education and live in a culture that, from the very
            first moment, I found fascinating.
          `,
        },
      ],
    },
    comments: {
      title: "What our students say about us",
      items: [
        {
          name: "Stefanie H",
          body: `I'm really happy to be studying Spanish at this school in Barcelona. Carla,
            my advisor, informed me very well about everything, and it was great to have
            her as my first point of contact! The classes are structured and clear, and
            the teacher adapts to the pace of the class and explains everything well. I
            definitely recommend this school!`,
        },
        {
          name: "Jan E",
          body: `If you are serious about learning Spanish and meeting amazing people, don't
            look any further.I tried learning Spanish in another academy and on Babel
            but nothing works like NL college. And the service definitely surpasses the
            price. Also special appreciation for my advisor Carla Oyarce which was
            extremely resourceful and welcoming pre course and along the whole journey.
            She goes above and beyond to provide extra care for all learners.`,
        },
        {
          name: "Blendi B",
          body: `I've been attending this school for 2 months now, and it's great! Very
            friendly staff! The advisor Carla helped me out so much by answering all my
            questions and on boarding me to the school. The teachers know what they're
            doing, and always available to help you learn.  I highly recommend it!!`,
        },
      ],
    },
    video: {
      title: "Meet your teacher, Mireia",
      url: "https://www.youtube.com/embed/2bUJ1I8WoU4",
      button: "I WANT TO LEARN SPANISH",
    },
  },
  thanks: {
    title: "THANK YOU!",
    subtitle: "You’re one step closer to start learning Spanish for good!",
    text: "One of our agents will contact you soon.",
  },
};
